export default {
  id: "c-control-gc-c",
  name: "Control GC",
  type: "сascade",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data: "The control cabinet is designed to maintain and regulate the level of the controlled parameter (pressure of the water supply system, the level of filling of tanks, etc.), by sequential (cascade) connection of electric motors to maintain the set value of the parameter.",
        },
      ],
      [
        {
          type: "list",
          data: [
            "pressure boosting systems;",
            "buildings, sports facilities water supply;",
            "water supply for industrial facilities;",
            "irrigation systems;",
            "sprinkling system;",
            "drinking water treatment plants.",
          ],
        },
      ],
    ],
    specifications: [
      {
        name: "Main functions",
        data: [
          {
            type: "list",
            data: [
              "Protection of pump motors;",
              "Automatic and manual pump control;",
              "Automatic start of main pumps in accordance with control signals;",
              "Automatic start of the backup pump in case of failure or main pump  underperformance for a specified time;",
              "Manual shutdown of automatic start-up of pumps while maintaining the possibility of manual start;",
              "System status indication by means of light indication;",
              'Sound signaling of the "FAILURE" state;',
              "Visual display of the system state and setting the necessary parameters using the operator panel;",
              "System state dispatching using dry contacts (standard) or interface communication line (optional).",
            ],
          },
        ],
      },
      {
        name: "Distinctive features",
        data: [
          {
            type: "list",
            data: [
              'Сonformity with TP TC 004/2011 "About safety of low voltage equipment";',
              'Сonformity with TR CU 020/2011 "Electromagnetic compatibility of technical equipment";',
              "Intuitive interface;",
              "Customization flexibility.",
            ],
          },
        ],
      },
      {
        name: "Additional options",
        data: [
          {
            type: "list",
            data: [
              "Light and sound alarm;",
              "Selection of operating modes;",
              "The ability to customize the required parameters;",
              "Dispatching using dry contacts (standard) or interface communication line (optional);",
            ],
          },
        ],
      },
    ],
  },
};
