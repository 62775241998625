import mini from "./mini";
import medium from "./medium";
import large from "./large";

import { IMAGES_PATH, PRODUCT_IMAGES_PATH } from "config/path";

export default {
  id: "block-containers",
  name: "Block containers",
  image: {
    home: {},
  },
  homePageSlide: {
    image: {
      src: `${PRODUCT_IMAGES_PATH}/block-containers/mini/bc-4000/slide.png`,
      alt: "",
    },
    backgroundColor: "",
  },
  pageContent: {
    product: {
      advantages: {
        title: "Advantages\nour block containers",
        slides: [
          {
            label: "Safety",
            content: {
              body: [
                "The main material for the insulation of our block of containers is a safe non-combustible mineral wool insulation, the thickness of which is dependent on the region of operation and purpose of the product.",
                "We offer modern construction and other containers of high quality.",
              ],
              footer: "GOST 12.3.002, GOST 12.2.003",
            },
          },
          {
            label: "Warranty",
            content: {
              body: [
                "When constructing buildings, our builders are guided by drawings. After the completion of construction, the compliance of the work performed with the design documentation is checked.",
                "We guarantee the high quality of our buildings, as we control all stages of production and installation.",
              ],
              footer: "1 year warranty",
            },
          },
        ],
      },
      gallery: [{}, {}, {}, {}, {}],
    },
  },
  navThumbnail: {
    image: {
      src: `${IMAGES_PATH}/products/block-containers/nav-thumbnail-1.jpg`,
      alt: "",
    },
  },
  subcategories: [mini, medium, large],
};
