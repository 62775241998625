import { AppColor } from "config";
import hydroGf from "./hydro-gf";

const subcategory = {
  id: "firefighting",
  name: "Fire extinguishment",
  color: AppColor.DARK_RED,
  products: [hydroGf],
};

export default subcategory;
