import { getProductManualSrc } from "utils/path";

export default {
  id: "control-gl-mf-magelis",
  name: "Control GL",
  type: "мультичастотный ОП&nbsp;Magelis",

  about: {
    description: [
      [
        {
          type: "text",
          data:
            "Система управления типа Control-GL) осуществляет управление всеми подключенными электродвигателями (насосами). Количество управляемых электродвигателей (насосов) может быть от&nbsp;2&nbsp;до&nbsp;6.",
        },
        {
          type: "text",
          data:
            "Каждый электродвигатель (насос) управляется при помощи преобразователя частоты. При нехватке производительности данного электродвигателя (насоса) свободный электродвигатель (насос) с&nbsp;максимальным временем простоя подключается к&nbsp;следующему преобразователю частоты и&nbsp;регулирование продолжается",
        },
      ],
      [
        {
          type: "list",
          data: [
            "Соответствие TP&nbsp;TC&nbsp;004/2011 &laquo;О&nbsp;безопасности низковольтного оборудования&raquo;;",
            "Соответствие ТР&nbsp;ТС&nbsp;020/2011 &laquo;Электромагнитная совместимость технических средств&raquo;;",
            "Интуитивно понятный интерфейс;",
            "Гибкость настройки;",
            "Удобный сенсорный дисплей",
          ],
        },
      ],
    ],
    specifications: [
      {
        name: "Основные функции",
        data: [
          {
            type: "list",
            data: [
              "Защита электродвигателя насоса;",
              "Автоматическое и&nbsp;ручное управление насосом;",
              "Автоматический пуск основного насоса в&nbsp;соответствии с&nbsp;сигналами управления;",
              "Автоматический пуск резервного насоса в&nbsp;случае отказа или невыхода основного насоса на&nbsp;режим в&nbsp;течение заданного времени;",
              "Ручное отключение автоматического пуска насоса с&nbsp;сохранением возможности ручного пуска;",
              "Индикация состояния системы с&nbsp;помощью световой индикации;",
              "Визуальное отображение состояния системы и&nbsp;настройка необходимых параметров с помощью панели оператора;",
              "Звуковая сигнализация состояния &laquo;НЕИСПРАВНОСТЬ&raquo;;",
              "Диспетчеризация состояния системы с&nbsp;помощью сухих контактов (стандартно) или интерфейсной линия связи (опционально);",
              "Плавное регулирование при помощи преобразователя частоты.",
            ],
          },
        ],
      },
      {
        name: "Документация",
        data: [
          {
            type: "cards",
            data: [
              {
                type: "documentation",
                data: {
                  caption: "руководство по эксплуатации",
                  title: "Control GL",
                  subtitle: "мультичастотный ОП&nbsp;Magelis",
                  file: getProductManualSrc({
                    categoryId: "control-cabinets",
                    subcategoryId: "water-supply",
                    productId: "control-gl-mf-magelis",
                  }),
                },
              },
            ],
          },
        ],
      },
    ],
  },
};
