import waterSupply from "./waterSupply";
import firefighting from "./firefighting";
import drainage from "./drainage";
import { ChartBarLvl } from "components/pages/Product/UseCases/UseCasesStatisticChartBar";
import { IMAGES_PATH } from "config/path";
import { AppColor } from "config";

export const subcategory = {
  [waterSupply.id]: waterSupply,
  [firefighting.id]: firefighting,
  [drainage.id]: drainage,
};

const pumpingStations = {
  id: "pumping-stations",
  name: "Pumping stations",
  color: AppColor.BLUE,
  pageContent: {
    product: {
      advantages: {
        title: "Advantages\nour pumping stations",
        slides: [
          {
            label: "Safety",
            content: {
              body: [
                "Pumping stations belong to the 1-th category of reliability according to reliability of operation, power supply and water supply (SNiP 2.04.02-84).",
                "All equipment and devices must have a safety certificate.",
              ],
              footer: "GOST 1.2-2009",
            },
          },
          {
            label: "Warranty",
            content: {
              body: [
                "All pumps and pumping equipment have a warranty period.",
                "If during the warranty period of operation the pump fails due to the fault of the Manufacturer, a free warranty repair of the pump is provided in accordance with the conditions and procedure for warranty service of the pumps.",
              ],
              footer: "1 year warranty",
            },
          },
          {
            label: "Details",
            content: {
              body: [
                "We work only with trusted suppliers",
                "parts for assembling pumping stations and control cabinets meet all quality and safety standards",
              ],
              footer: "1 year warranty",
            },
          },
        ],
      },
      useCases: {
        title: "Pumping stations Hydro G",
        description: [
          "Hydro G pumping stations include GRUNDFOS pumps, stainless steel suction and discharge pipes, valves and check valves, Control G control cabinet, pressure transmitters and manometers",
          [
            "irrigation systems",
            "firefighting",
            "increasing (maintaining) pressure in cold and hot water supply systems",
          ],
        ],
        table: {
          head: [
            "Administrative buildings",
            "Industrial buildings",
            "Residential buildings",
            "Sewerage systems",
            "Water intake and drainage systems",
            "Circular pumping stations",
          ],
          body: [
            {
              label: "Hydro GC",
              data: [
                ChartBarLvl.Unacceptable,
                ChartBarLvl.Well,
                ChartBarLvl.Good,
                ChartBarLvl.Acceptable,
                ChartBarLvl.Good,
                ChartBarLvl.Unacceptable,
              ],
            },
            {
              label: "Hydro GI",
              data: [
                ChartBarLvl.Good,
                ChartBarLvl.Acceptable,
                ChartBarLvl.Unacceptable,
                ChartBarLvl.Acceptable,
                ChartBarLvl.Good,
                ChartBarLvl.Good,
              ],
            },
            {
              label: "Hydro GL",
              data: [
                ChartBarLvl.Acceptable,
                ChartBarLvl.Excellent,
                ChartBarLvl.Well,
                ChartBarLvl.Excellent,
                ChartBarLvl.Well,
                ChartBarLvl.Good,
              ],
            },
            {
              label: "Hydro GF",
              data: [
                ChartBarLvl.Well,
                ChartBarLvl.Good,
                ChartBarLvl.Acceptable,
                ChartBarLvl.Good,
                ChartBarLvl.Good,
                ChartBarLvl.Well,
              ],
            },
            {
              label: "Hydro GFS",
              data: [
                ChartBarLvl.Good,
                ChartBarLvl.Unacceptable,
                ChartBarLvl.Acceptable,
                ChartBarLvl.Good,
                ChartBarLvl.Good,
                ChartBarLvl.Good,
              ],
            },
            {
              label: "Hydro GFY",
              data: [
                ChartBarLvl.Acceptable,
                ChartBarLvl.Acceptable,
                ChartBarLvl.Unacceptable,
                ChartBarLvl.Well,
                ChartBarLvl.Excellent,
                ChartBarLvl.Well,
              ],
            },
            {
              label: "Hydro GK",
              data: [
                ChartBarLvl.Acceptable,
                ChartBarLvl.Well,
                ChartBarLvl.Good,
                ChartBarLvl.Excellent,
                ChartBarLvl.Good,
                ChartBarLvl.Acceptable,
              ],
            },
            {
              label: "Hydro GKS",
              data: [
                ChartBarLvl.Acceptable,
                ChartBarLvl.Excellent,
                ChartBarLvl.Excellent,
                ChartBarLvl.Well,
                ChartBarLvl.Excellent,
                ChartBarLvl.Good,
              ],
            },
          ],
        },
      },
      gallery: [{}, {}, {}, {}, {}],
    },
  },
  navThumbnail: {
    image: {
      src: `${IMAGES_PATH}/products/pumping-stations/nav-thumbnail-1.jpg`,
      alt: "",
    },
  },
  subcategories: [waterSupply, firefighting, drainage],
};

export default pumpingStations;
