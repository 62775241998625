export default {
  id: "c-control-gi-s",
  name: "Control GI",
  // type: "стандартный",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data:
            "Система управления типа Control GI&nbsp;осуществляет управление всеми подключенными электродвигателями (насосами). Количество управляемых электродвигателей(насосов) может быть от&nbsp;2&nbsp;до&nbsp;5.",
        },
        {
          type: "text",
          data:
            "Один электродвигатель (насос) управляется при помощи преобразователя частоты. При нехватке производительности данного электродвигателя (насоса) он&nbsp;подключается непосредственно к&nbsp;питающей сети, а&nbsp;свободный электродвигатель (насос) с&nbsp;максимальным временем простоя подключается к&nbsp;преобразователю частоты и&nbsp;регулирование продолжается.",
        },
      ],
      [
        {
          type: "list",
          data: [
            "Соответствие TP&nbsp;TC&nbsp;004/2011 &laquo;О&nbsp;безопасности низковольтного оборудования&raquo;;",
            "Соответствие ТР&nbsp;ТС&nbsp;020/2011 &laquo;Электромагнитная совместимость технических средств&raquo;;",
            "Интуитивно понятный интерфейс;",
            "Гибкость настройки;",
            "Наличие резервных насосов.",
          ],
        },
      ],
    ],
    specifications: [
      {
        name: "Основные функции",
        data: [
          {
            type: "list",
            data: [
              "Защита электродвигателей насосов;",
              "Автоматическое и&nbsp;ручное управление насосами;",
              "Автоматический пуск основных насосов в&nbsp;соответствии с&nbsp;сигналами управления;",
              "Автоматический пуск резервного насоса в&nbsp;случае отказа или невыхода основного насоса на&nbsp;режим в&nbsp;течение заданного времени;",
              "Ручное отключение автоматического пуска насосов с&nbsp;сохранением возможности ручного пуска;",
              "Индикация состояния системы с&nbsp;помощью световой индикации;",
              "Звуковая сигнализация состояния &laquo;НЕИСПРАВНОСТЬ&raquo;;",
              "Визуальное отображение состояния системы и&nbsp;настройка необходимых параметров с помощью панели оператора;",
              "Диспетчеризация состояния системы с&nbsp;помощью сухих контактов (стандартно) или интерфейсной линия связи (опционально);",
              "Плавное регулирование при помощи преобразователя частоты.",
            ],
          },
        ],
      },
      {
        name: "Дополнительные опции",
        data: [
          {
            type: "list",
            data: [
              "Предусматривает световую и&nbsp;звуковую сигнализацию, выбор режимов работы и&nbsp;возможность настройки необходимых параметров, диспетчеризацию с&nbsp;помощью сухих контактов (стандартно) или интерфейсной линии связи (опционально).",
              "Настраивается и&nbsp;тестируется на&nbsp;предприятии изготовителе. Оборудование поставляется готовым к&nbsp;подключению и&nbsp;эксплуатации.",
            ],
          },
        ],
      },
    ],
  },
};
