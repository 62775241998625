import React from "react";

import ContactsHeroItem from "./ContactsHeroItem";
import SlideUpOnScroll from "components/common/RevaelOnScroll/SlideUpOnScroll";
import { useTranslation } from "react-i18next";

function ContactsHeroRequisites() {
  const { t } = useTranslation();
  const requisites = t("contacts:requisites.items", { returnObjects: true });
  const addresses = t("contacts:addresses.items", { returnObjects: true });

  return (
    <div className="contacts-requisites">
      <div className="contacts-requisites__inner row">
        <div className="contacts-requisites__header col-12 col-xl-3 container_overflow-hidden">
          <SlideUpOnScroll>
            <h2 className="title title_section_secondary">
              {t("contacts:requisites.title")}
            </h2>
          </SlideUpOnScroll>
        </div>
        <div className="contacts-requisites__body col-12 col-xl-9">
          <ul className="contacts-requisites__list row list">
            {requisites.map((requisite, i) => (
              <li
                key={i}
                className="contacts-requisites__item col-12 col-sm-6 col-xl-4"
              >
                <ContactsHeroItem
                  caption={requisite.name}
                  meta={requisite.value}
                />
              </li>
            ))}
          </ul>
          <ul className="contacts-requisites__list row list">
            {addresses.map((address, i) => (
              <li
                key={i}
                className="contacts-requisites__item col-12 col-sm-6 col-xl-4"
              >
                <ContactsHeroItem
                  caption={address.name}
                  meta={[address.postcode, address.address]}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

ContactsHeroRequisites.propTypes = {};

export default ContactsHeroRequisites;
