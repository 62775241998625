export default {
  id: "oao-snpz",
  name: "ОАО СНПЗ",
  location: "г. Сызрань",
  year: 2019,

  page: {
    facility: {
      about: [
        {
          title: "В&nbsp;рамках работы по&nbsp;проекту мы&nbsp;выполнили",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Произведен комплекс работ по&nbsp;разработке и&nbsp;изготовлению оборудования;</li>
                  <li>Выполнен шеф-монтаж и&nbsp;пусконаладка автоматической насосной установки на&nbsp;объекте заказчика.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "about-works-done.webp",
          },
        },
        {
          title: "О&nbsp;заказчике",
          content: [
            {
              type: "html",
              data: `
                <p>Проектная мощность НПЗ составляет 8,5&nbsp;млн.&nbsp;т&nbsp;нефти в&nbsp;год. Завод перерабатывает Западно-Сибирскую нефть, нефть Оренбургских месторождений, а&nbsp;также нефть, добываемую Компанией в&nbsp;Самарской области.</p>
                <p>Вторичные перерабатывающие мощности завода включают установки каталитического риформинга, гидроочистки топлив, легкого гидрокрекинга, каталитического и&nbsp;термического крекинга, изомеризации, битумную и&nbsp;газофракционную установки, блок выделения бензолсодержащей фракции. Завод выпускает широкую номенклатуру нефтепродуктов, включая высококачественное моторное топливо, низкосернистое судовое топливо RMLS 40&nbsp;вид Э&nbsp;II, битум.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}],
    },
  },
};
