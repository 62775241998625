import waterSupply from "./waterSupply";
import firefighting from "./firefighting";
import drainage from "./drainage";
import { IMAGES_PATH } from "config/path";
import { AppColor } from "config";

const controlCabinets = {
  id: "control-cabinets",
  name: "Control cabinets",
  color: AppColor.RED,
  image: {
    home: {},
  },
  pageContent: {
    product: {
      advantages: {
        title: "Advantages\nour wardrobes",
        slides: [
          {
            label: "Safety",
            content: {
              body: [
                "Compliance with all fire safety standards",
                "The state of the work performed is confirmed by the SDS RER certificate and corresponds to the quality management system",
              ],
              footer: "GOST 51321.1-2017",
            },
          },
          {
            label: "Warranty",
            content: {
              body: [
                "Guarantee of operability of control cabinets and compliance with the requirements of technical conditions, subject to the conditions of transportation, storage, installation and operation specified in this passport and manual on operation",
                "Warranty repairs are performed by the manufacturer or another company that has an agreement with the manufacturer to perform these works.",
              ],
              footer: "3 year warranty",
            },
          },
          {
            label: "Details",
            content: {
              body: [
                "We work only with verified suppliers",
                "parts for assembling pumping stations and control cabinets meet all quality and safety standards",
              ],
              footer: "1 year warranty",
            },
          },
        ],
      },
      useCases: {
        title: "Control G Series Cabinets",
        description: [
          "are designed to increase (maintain) the value of the required parameter (pressure, temperature) by controlling a group of pumps in systems",
          [
            "water treatment",
            "irrigation",
            "fire extinguishing",
            "hot and cold water supply",
            "drainage",
          ],
        ],
        table: {
          head: [
            "Administrative buildings",
            "Industrial buildings",
            "Residential buildings",
            "Sewerage systems",
            "Water intake and drainage systems",
            "Circular pumping stations",
          ],
          body: [
            {
              label: "Control GC",
              data: [40, 60, 20, 0, 40, 0],
            },
            {
              label: "Control GI",
              data: [40, 20, 20, 40, 40, 40],
            },
            {
              label: "Control GL",
              data: [20, 80, 60, 80, 80, 20],
            },
            {
              label: "Control GF",
              data: [80, 60, 20, 40, 40, 60],
            },
            {
              label: "Control GK",
              data: [40, 20, 20, 40, 40, 40],
            },
            {
              label: "Control GKS",
              data: [20, 0, 0, 80, 80, 40],
            },
          ],
        },
      },
      gallery: [{}, {}, {}, {}],
    },
  },
  navThumbnail: {
    image: {
      src: `${IMAGES_PATH}/products/control-cabinets/nav-thumbnail-1.jpg`,
      alt: "",
    },
  },
  subcategories: [waterSupply, firefighting, drainage],
};

export default controlCabinets;
