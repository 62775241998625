export default {
  id: "zap-sib-neftekhim",
  name: "ZapSibNeftekhim",
  location: "Tobolsk",
  year: 2020,

  page: {
    facility: {
      about: [
        {
          title: "As part of the work on the project, we have made",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Design, development and testing of a pumping unit;</li>
                  <li>Delivery of equipment by a transport company to the client’s address;</li>
                  <li>Installation and commissioning of the pumping unit.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "facility-about.webp",
          },
        },
        {
          title: "About the client",
          content: [
            {
              type: "html",
              data: `
                <p>Ltd ZapSibNeftekhim is a hydrocarbon processing and polymers production plant, which is part of SIBUR.</p>
                <p>The production facilities of ZapSibNeftekhim include a central gas fractionation unit for processing a wide fraction of light hydrocarbons with a capacity of 8 million tons per year, production of monomers for liquefied hydrocarbon gases, butadiene, isobutylene, and methyl tert-butyl ether output; production of polymers for the polypropylene output with a capacity of 500 thousand tons per year; production of electric heat and steam generation with a capacity of 665 MW for electric energy and 2,585 MW for thermal energy.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}, {}],
    },
  },
};
