import statusA11 from "./status-a-1-1";
import statusA28 from "./status-a-2-8";

const subcategory = {
  id: "repeaters",
  name: "Повторители",
  products: [statusA11],
};

export default subcategory;
