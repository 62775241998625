export default {
  id: "control-gf",
  name: "Control GF",
  type: "standard",
  image: {
    main: {
      file: {
        ext: "webp",
      },
    },
  },
  about: {
    description: [
      [
        {
          type: "text",
          data: "designed for automatic control of water fire extinguishing pumps with standard asynchronous squirrel-cage motors according to control signals.",
        },
      ],
      [
        {
          type: "list",
          data: [
            "drencher fire extinguishing system",
            "combined fire extinguishing system",
          ],
        },
      ],
    ],
    specifications: [
      {
        name: "Main functions",
        data: [
          {
            type: "list",
            data: [
              "Protection of pump motors;",
              "Automatic and manual control of fire pumps;",
              "Automatic and manual control of the delivery pump (optional);",
              "Automatic and manual control of electric motors (optional);",
              "Automatic and manual control of the drain pump;",
              "Manual shutdown of automatic start-up of pumps while maintaining the possibility of manual start;",
              "Delay of the fire pumps start up;",
              "Disconnection of household drinking pumps (pumps are not included in the delivery set);",
              "Automatic start of main pumps in accordance with control signals;",
              "System status indication by means of light indication;",
              'Sound signaling of "FIRE" and "FAILURE" state;',
              "Automatic start of the backup pump in case of failure or main pump  underperformance for a specified time;",
              "Visual display of the system status and setting the required parameters using the operator's touch panel;",
              "System state dispatching using dry contacts (standard) or interface communication line (optional).",
            ],
          },
        ],
      },
      {
        name: "Distinctive features",
        data: [
          {
            type: "list",
            data: [
              "Compliance with all-Union State Standard 53325 Ch. 7;",
              "Customization flexibility.",
              "Intuitive interface;",
              "Selection of operating modes;",
              "Optimal work algorithms;",
            ],
          },
        ],
      },
      {
        name: "Additional options",
        data: [
          {
            type: "list",
            data: [
              "Light and sound alarm;",
              "The ability to customize the required parameters;",
              "Dispatching using dry contacts (standard) or interface communication line (optional);",
            ],
          },
        ],
      },
    ],
  },
};
