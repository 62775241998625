import { AppColor } from "config";
import controlGk from "./control-gk";
import controlGks from "./control-gks";

const subcategory = {
  id: "drainage",
  name: "Wastewater disposal",
  color: AppColor.DARK_GREEN,
  products: [controlGk, controlGks],
};

export default subcategory;
