import { AppColor } from "config";

import cControlGc from "./c-control-gc-c";
import cControlGiS from "./c-control-gi-s";
import cControlGieSf from "./c-control-gie-sf";
import cControlGlMfMagelis from "./c-control-gl-mf-magelis";

const subcategory = {
  id: "water-supply",
  name: "Water supply",
  color: AppColor.BLUE,
  products: [cControlGc, cControlGiS, cControlGieSf, cControlGlMfMagelis],
};

export default subcategory;
