export default {
  id: "control-gk",
  name: "Control GK",
  type: "float",
  image: {
    main: {
      file: {
        ext: "webp",
      },
    },
  },
  about: {
    description: [
      [
        {
          type: "text",
          data: "used in administrative, industrial, residential buildings, sewage treatment plants, boiler rooms. They are designed to increase (maintain) the value of the required parameter by controlling a group of pumps in systems",
        },
      ],
      [
        {
          type: "list",
          data: [
            "level maintenance systems;",
            "delivery liquids systems;",
            "sewerage systems;",
            "buildings, sports objects drainage;",
            "water disposal of industrial facilities;",
            "sewage pumping stations.",
          ],
        },
      ],
    ],
    specifications: [
      {
        name: "Main functions",
        data: [
          {
            type: "list",
            data: [
              "Control of the water level in the tank using a hydrostatic level sensor or floats (adjustable);",
              "Turn on the pump with the longest downtime;",
              "Prevention of pump silting;",
              "Blocking the pump when overheating and / or leaking;",
              "Protection of lines and electrical installations against overloads and short-circuit currents;",
              "Control of the number of pump start-ups for a set period;",
              "Monitoring and formation of an accident of the hydrostatic level sensor for open circuit, short circuit and incorrect level value;",
              "Control of the number of pump alarm activation and blocking in case of exceeding alarms for a set period;",
              'Formation of an accident "alternation of floats";',
              "Formation of accidents and events history;",
              'Dispatching the signal "General alarm", "Overflow" by dry contacts (GSM modem - optional).',
            ],
          },
        ],
      },
      {
        name: "Distinctive features",
        data: [
          {
            type: "list",
            data: [
              'Compliance with TP TC 004/2011 "About safety of low voltage equipment";',
              'Compliance with TR CU 020/2011 "Electromagnetic compatibility of technical equipment";',
              "Customization flexibility;",
              "Intuitive interface;",
              "Control of the liquid level in the tank.",
            ],
          },
        ],
      },
      {
        name: "Additional options",
        data: [
          {
            type: "list",
            data: [
              "Light and sound alarm;",
              "Selection of operating modes;",
              "The ability to customize the required parameters;",
              "Dispatching using dry contacts (standard) or interface communication line (optional);",
            ],
          },
        ],
      },
    ],
  },
};
