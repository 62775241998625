import grandMaster from "./grand-master";
import zipSibNeftekhim from "./zap-sib-neftekhim";
import paoNlmk from "./pao-nlmk";
import nevinnomysskyAzot from "./nevinnomyssky-azot";
import oaoSnpz from "./oao-snpz";
import golderHorn from "./golder-horn";

const facilities = {
  facilities: [
    grandMaster,
    zipSibNeftekhim,
    paoNlmk,
    nevinnomysskyAzot,
    oaoSnpz,
    golderHorn,
  ],
};

export default facilities;
