import { Home } from "components/pages";

const route = {
  path: "/",
  exact: true,
  component: Home,
  className: "home",
  nav: {
    label: "home",
  },
};

export default route;
