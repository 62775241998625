import React, { useCallback, useMemo, useRef } from "react";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import ProductsNav from "components/pages/Products/ProductsNav";
import HeaderSubNav from "./HeaderSubNav";

import homeRoute from "routes/home";
import productsRoute from "routes/products";
import documentationRoute from "routes/documentation";
import facilitiesRoute from "routes/facilities";
import aboutRoute from "routes/about";
import contactsRoute from "routes/contacts";
import { Theme } from "config";
import { useTranslation } from "react-i18next";

export const headerNavRoutes = [
  homeRoute,
  productsRoute,
  documentationRoute,
  facilitiesRoute,
  aboutRoute,
  contactsRoute,
];

const HeaderNav = ({ className, headerTheme, setHeaderTheme }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const lastHeaderThemeRef = useRef(headerTheme);

  const isProductsPage = useMemo(
    () => location.pathname === productsRoute.path,
    [location.pathname]
  );

  const handlers = useMemo(() => {
    return {
      onMouseEnter() {
        lastHeaderThemeRef.current = headerTheme;

        setHeaderTheme(Theme.DARK);
      },
      onMouseLeave() {
        setHeaderTheme(lastHeaderThemeRef.current);
      },
    };
  }, [headerTheme, setHeaderTheme]);

  return (
    <nav className={classNames("header-nav", "nav", className)}>
      <ul className="header-nav__list nav__list list">
        {headerNavRoutes.map((route, i) => {
          const hasProductSubNav =
            !isProductsPage && productsRoute.path === route.path;

          return (
            route.nav && (
              <li
                {...(hasProductSubNav ? handlers : {})}
                className={classNames(
                  "header-nav__item nav__item",
                  hasProductSubNav && "header-nav__item_has-sub-nav"
                )}
                key={i}
              >
                <NavLink
                  className={classNames(
                    "header-nav__link header__link nav__link",
                    hasProductSubNav && "header-nav__link_has-sub-nav"
                  )}
                  exact={route.exact}
                  to={route?.nav?.href || route.path}
                >
                  {t(`main-nav-items.${route.nav.label}`)}
                </NavLink>

                {hasProductSubNav && (
                  <HeaderSubNav>
                    <ProductsNav />
                  </HeaderSubNav>
                )}
              </li>
            )
          );
        })}
      </ul>
    </nav>
  );
};

export default HeaderNav;
