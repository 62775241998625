export default {
  id: "grand-master",
  name: "Grand Master",
  location: "г. Москва",
  year: 2019,

  page: {
    facility: {
      gallery: [{}, {}, {}],
      about: [
        {
          title: "В рамках работы по проекту мы выполнили",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Произведены все монтажные работы по оборудованию;</li>
                  <li>Выполнены работы по подключению и настройке насосных станций повышения давления, а также ввод в эксплуатацию оборудования с соблюдением всех требований.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "about-project.webp",
          },
        },
        {
          title: "О заказчике:",
          content: [
            {
              type: "html",
              data: `
                <p>Техника MIE и Grand Master представлена во всех крупных городах России, в том числе в фирменных магазинах компании, в сетевых магазинах бытовой техники и электроники и интернет-гипермаркетах.</p>
                <p>Grand Master представляет обширную сеть авторизованных сервисных центров, осуществляющих гарантийное и послегарантийное обслуживание техники, поставляет запасные части и аксессуары.</p>
              `,
            },
          ],
        },
      ],
    },
  },
};
