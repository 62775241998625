export default {
  id: "hydro-gc",
  name: "Hydro GC",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data: "HYDRO GC pumping stations are designed for pumping, increasing and maintaining pressure in water supply systems - industry, housing and communal services, agriculture.",
        },
      ],
    ],
    specifications: [
      {
        name: "Main functions",
        data: [
          [
            {
              type: "list",
              data: [
                "Maintaining the set pressure in the outlet pipe;",
                "Alternating pumps for even wear and life-extension;",
                "Automatic change in the number of running pumps when changing the water water draw-off.",
              ],
            },
          ],
        ],
      },
      {
        name: "Distinctive features",
        data: [
          {
            type: "list",
            data: [
              "Maintaining the set pressure;",
              "Automatic restart after troubleshooting process;",
              "Protection of pumps from running without water;",
              "Protection against loss, asymmetry and incorrect phase sequence;",
              "Protection of pumps against overcurrent and short circuit;",
              "Low cost compared to original stations ABS, Grundfos, KSB, Wilo, FLYGT.",
            ],
          },
        ],
      },
      {
        name: "Additional options",
        data: [
          {
            type: "list",
            data: [
              "Dry contacts dispatching;",
              "Built-in ATS;",
              "Soft starters.",
            ],
          },
        ],
      },
    ],
  },
};
