import classNames from "classnames";
import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { matchPath, useLocation } from "react-router-dom";
import homeRoute from "routes/home";
import productRoute from "routes/product";
import productsRoute from "routes/products";
import facilityRoute from "routes/facility";
import facilitiesRoute from "routes/facilities";

import MenuHeader from "./MenuHeader";
import MenuBody from "./MenuBody";
import MenuFooter from "./MenuFooter";

import { headerNavRoutes } from "components/layouts/Header/HeaderNav";
import { TransitionGroup, Transition } from "react-transition-group";
import { BodyScrollContext } from "App";
import { useEffect } from "react";

const headerNavRoutesWithoutHomeRoute = headerNavRoutes.filter(
  (route) => route.path !== homeRoute.path
);

const Menu = forwardRef(({ className, open }, ref) => {
  const elRef = useRef(null);
  const location = useLocation();
  const { scrollbarGap } = useContext(BodyScrollContext);

  useEffect(() => {
    /**
     * @type HTMLElement
     */
    const el = elRef.current;

    const updateHeight = () => {
      el.style.height = "100vh";

      el.style.height = `calc(100vh - ${
        el.offsetHeight - window.innerHeight
      }px)`;
    };

    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const currentRoute = useMemo(() => {
    if (location.pathname === homeRoute.path) {
      return homeRoute;
    }

    if (
      matchPath(location.pathname, {
        path: productRoute.path,
        exact: productRoute.exact,
      })
    ) {
      return productsRoute;
    }

    if (
      matchPath(location.pathname, {
        path: facilityRoute.path,
        exact: facilityRoute.exact,
      })
    ) {
      return facilitiesRoute;
    }

    return (
      headerNavRoutesWithoutHomeRoute.find((route) => {
        return (
          location.pathname === route.path ||
          matchPath(location.pathname, {
            path: route.path,
            exact: route.exact,
          })
        );
      }) || homeRoute
    );
  }, [location.pathname]);

  const bodyRoutes = useMemo(
    () => headerNavRoutes.filter((route) => route.path !== currentRoute.path),
    [currentRoute.path]
  );

  useImperativeHandle(ref, () => elRef.current);

  return (
    <div
      ref={elRef}
      className={classNames("menu", className, { active: open })}
      style={{
        paddingRight: scrollbarGap,
      }}
    >
      <div className="menu__container container row">
        <TransitionGroup component={null}>
          <Transition key={currentRoute.path} timeout={800}>
            {(stage) => (
              <div
                className={classNames(
                  "menu__inner col-12 col-md-10 offset-md-2",
                  `menu__inner_${stage}`
                )}
              >
                <MenuHeader currentRoute={currentRoute} />
                <MenuBody routes={bodyRoutes} />
                <MenuFooter />
              </div>
            )}
          </Transition>
        </TransitionGroup>
      </div>
    </div>
  );
});

export default Menu;
