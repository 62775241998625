export default {
  id: "golden-horn",
  name: "Золотой Рог",
  location: "Пос. Октябрьский",
  year: 2017,

  page: {
    facility: {
      about: [
        {
          title: "В&nbsp;рамках работы по&nbsp;проекту мы&nbsp;выполнили",
          content: [
            {
              type: "html",
              data: `
              <ul>
                <li>Выезд на&nbsp;объект заказчика для консультации и&nbsp;оценки эксплуатационных условий;</li>
                <li>Разработка и&nbsp;испытание насосной установки, монтаж.</li>
              </ul>
              `,
            },
          ],
          image: {
            file: "about-works-done.webp",
          },
        },
        {
          title: "О&nbsp;заказчике",
          content: [
            {
              type: "html",
              data: `
                <p>На&nbsp;сегодняшний день, ООО &laquo;Мясокомбинат &laquo;Золотой Рог&raquo; имеет сильную производственную базу, включающую в&nbsp;себя производственный, административный и&nbsp;транспортный корпуса.</p>
                <p>Производство оснащено современным германским оборудованием&nbsp;&mdash; известных в&nbsp;мире компании: KG&nbsp;Wetter, Vemag, Polyclip, Hadtman, Varlovac, Bastra. Имеющееся оборудование оснащено системой контроля за&nbsp;безопасностью качества, что позволяет исключить &laquo;человеческий фактор&raquo;.</p>
                <p>Колбасные изделия и&nbsp;деликатесы производятся только из&nbsp;охлажденного мяса, что позволяет сохранить вкусовые качества изделий.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}],
    },
  },
};
