export default {
  id: "hydro-gf",
  name: "Hydro GF",
  type: "standard",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data: "is designed to ensure guaranteed water supply to the fire extinguishing system in the event of an emergency situation.  corresponds to all norms, rules, technical regulations and joint venture.",
        },
      ],
    ],
    specifications: [
      {
        name: "Main functions",
        data: [
          [
            {
              type: "text",
              data: [
                "Maintaining the set pressure in the outlet pipe;",
                "Alternating pumps for even wear and life-extension;",
                "Automatic change in the number of running pumps when changing the water water draw-off.",
              ],
            },
          ],
        ],
      },
      {
        name: "Distinctive features",
        data: [
          [
            {
              type: "list",
              data: [
                "The pumping unit is completed with various modules for automation of processes and control of water supply, the main and backup pump, mounted on one common base frame;",
                "Suction and discharge manifolds, as well as the frame are made of stainless steel AISI 304 (AISI 304L);",
                "The sources of water supply can be both firewater pond, reservoirs or tanks, and the city water supply network.",
                "Spheres of application are industrial premises, warehouses, administrative and residential buildings, cultural centers, social institutions.",
                "Registration of pumping units failures and malfunctions with memory of events for 1 year.",
              ],
            },
          ],
        ],
      },
      {
        name: "Additional options",
        data: [
          {
            type: "list",
            data: [
              "Smooth start of pumps;",
              "Gate valves operation;",
              "Dispatching by common industrial interfaces.",
            ],
          },
        ],
      },
      {
        name: "Сharacteristics",
        data: [
          {
            type: "list",
            data: [
              "Nominal flow rate: up to 1000 m3 / h;",
              "Rated head: up to 250 m;",
              "Power supply: 3x380 V, 50 Hz;",
              "Pump power: up to 355 kW.",
            ],
          },
        ],
      },
    ],
  },
};
