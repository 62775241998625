import { Documentation } from "components/pages";

export const buildPathToDocumentation = (documentationId = "") => {
  if (documentationId) {
    documentationId = "/" + documentationId;
  }

  return `/documentation${documentationId}/`;
};

const route = {
  path: "/documentation/:documentationId?",
  exact: false,
  component: Documentation,
  className: "documentation",
  nav: {
    label: "documentation",
    href: buildPathToDocumentation(),
  },
};

export default route;
