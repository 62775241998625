import { AppColor } from "config";
import hydroGk from "./hydro-gk";

const subcategory = {
  id: "drainage",
  name: "Wastewater disposal",
  color: AppColor.DARK_GREEN,
  products: [hydroGk],
};

export default subcategory;
