import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const AppTitle = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === "en") {
      document.title = "Globus - engineering solutions";
    } else {
      document.title = "Globus - инженерные решения";
    }
  }, [i18n.language]);

  return null;
};

export default AppTitle;
