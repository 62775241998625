import statusIvC from "./status-iv-c";
import statusIvSf from "./status-iv-sf";

const subcategory = {
  id: "controllers",
  name: "Контроллеры",
  products: [statusIvC, statusIvSf],
};

export default subcategory;
