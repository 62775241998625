import { DOCUMENTS_PATH } from "config/path";
import { getProductManualSrc } from "utils/path";

export default {
  id: "status-u",
  name: "STATUS-U",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "html",
          data: "<p>designed to protect an electric motor or electrical installation powered by a three-phase network</p>",
        },
      ],
      [
        {
          type: "html",
          data: `
            <ul>
              <li>cascade algorithm (step maintenance of the controlled parameter);</li>
              <li>one frequency converter (stepless maintenance of the controlled parameter);</li>
              <li>float.</li>
            </ul>
          `,
        },
      ],
    ],
    specifications: [
      {
        name: "Applications",
        data: [
          {
            type: "html",
            data: `
            <ul>
            <li>The absence of at least one of the phases ("	loss of phase");</li>
            <li>Voltage overloading above the set threshold;</li>
            <li>Voltage reduction below the set threshold;</li>
            <li>Neutral break;</li>
            <li>Phase sequence	breaking;</li>
            <li>Voltage sticking and unbalance.</li>
          </ul>
            `,
          },
        ],
      },
      {
        name: "Distinctive features",
        data: [
          {
            type: "html",
            data: `
              <ul>
                <li>The device is designed to protect an electric motor or electrical installations powered by a three-phase network;</li>
                <li>Flexible configuration options for the device;</li>
                <li>Possibility of online tracking of three-phase network parameters;</li>
                <li>The possibility to read log files events;</li>
                <li>LED indication of current accident.</li>
              </ul>
            `,
          },
        ],
      },
      {
        name: "Documentation",
        data: [
          {
            type: "cards",
            data: [
              {
                type: "documentation",
                data: {
                  caption: "manual",
                  title: "STATUS-U",
                  file: getProductManualSrc({
                    categoryId: "electronics",
                    subcategoryId: "controllers",
                    productId: "status-u",
                  }),
                },
              },
            ],
          },
        ],
      },
    ],
  },
};
