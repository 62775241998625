import { Contacts } from "components/pages";

const route = {
  path: "/contacts",
  exact: true,
  component: Contacts,
  className: "contacts",
  nav: {
    label: "contacts",
  },
};

export default route;
