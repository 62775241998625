export default {
  id: "pao-nlmk",
  name: "ПАО НЛМК",
  location: "г. Липецк",
  year: 2020,

  page: {
    facility: {
      about: [
        {
          title: "В рамках работы по проекту мы выполнили",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Доставка насосных станций повышения давления на объект заказчика;</li>
                  <li>Установка и подключение оборудования;</li>
                  <li>Обучение персонала эксплуатации оборудования.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "about-project.webp",
          },
        },
        {
          title: "О заказчике",
          content: [
            {
              type: "html",
              data: `
                <p>НЛМК — предприятие с полным металлургическим циклом: от производства сырья для выплавки чугуна и стали до конечной продукции — плоского металлопроката с высокой добавленной стоимостью. Высококачественная металлопродукция НЛМК применяется в различных стратегически важных отраслях экономики, от строительства и машиностроения до производства энергетического оборудования и труб большого диаметра.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}],
    },
  },
};
