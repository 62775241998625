export default {
  id: "control-gks",
  name: "Control GKS",
  type: "прямой пуск",
  image: {
    main: {
      file: {
        ext: "webp",
      },
    },
  },
  about: {
    description: [
      [
        {
          type: "text",
          data: "Шкаф управления насосами водоотведения.",
        },
        {
          type: "text",
          data: "В зависимости от модификации изготавливается для работы в условиях, регламентированных для климатического исполнения УХЛ4, УХЛ1 по ГОСТ 15150-69.",
        },
      ],
      [
        {
          type: "list",
          data: [
            "Соответствие TP TC 004/2011 «О безопасности низковольтного оборудования»;",
            "Соответствие ТР ТС 020/2011 «Электромагнитная совместимость технических средств»;",
            "Интуитивно понятный интерфейс",
            "Гибкость настройки",
            "Сниженная нагрузка на питающую сеть во время пуска электродвигателей (насосов)",
          ],
        },
      ],
    ],
    specifications: [
      {
        name: "Основные функции",
        data: [
          {
            type: "list",
            data: [
              "Контроль уровня воды в резервуаре по гидростатическому датчику уровня либо по поплавкам (настраивается);",
              "Включение насоса с наибольшим временем простоя;",
              "Предотвращение заиливания насосов;",
              "Блокировка насоса при перегреве и/или протекании;",
              "Защита линий и электроустановок от перегрузок и токов короткого замыкания;",
              "Контроль количества пусков насоса за установленный период;",
              "Контроль и формирование аварии гидростатического датчика уровня на обрыв цепи, короткое замыкание цепи и неверного значения уровня;",
              "Контроль количества срабатывания аварии насоса и блокировка при превышении аварий за установленный период;",
              "Формирование аварии «чередование поплавков»;",
              "Формирование журнала аварий и событий;",
              "Диспетчеризация сигнала «Общая авария», «Перелив» путем сухих контактов (GSM модем — опционально).",
            ],
          },
        ],
      },
      {
        name: "Дополнительные опции",
        data: [
          {
            type: "list",
            data: [
              "Контроль качества сетевого питания и блокировка работы ШУН в случае некачественного питания (АВР — опционально);",
            ],
          },
        ],
      },
    ],
  },
};
