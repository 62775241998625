import statusIvC from "./status-iv-c";
import statusU from "./status-u";
import statusIvSf from "./status-iv-sf";

const subcategory = {
  id: "controllers",
  name: "Контроллеры",
  products: [statusIvC, statusU],
};

export default subcategory;
