export default {
  id: "hydro-gi",
  name: "Hydro GI",
  type: "стандартный",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data:
            "предназначены для перекачивания, повышения и&nbsp;поддержания давления в&nbsp;системах водоснабжения&nbsp;&mdash; промышленности, ЖКХ, сельского хозяйства.",
        },
      ],
    ],
    specifications: [
      {
        name: "Область применения",
        data: [
          [
            {
              type: "list",
              data: [
                "Системы повышения давления",
                "Водоснабжение зданий, спортивных объектов",
                "Ирригационные системы",
              ],
            },
            {
              type: "list",
              data: [
                "Водоснабжение зданий, спортивных объектов",
                "Системы орошения",
                "Станции очистки питьевой воды",
              ],
            },
          ],
        ],
      },
      {
        name: "Основные функции",
        data: [
          {
            type: "list",
            data: [
              "Поддержание заданного давления в&nbsp;выходном трубопроводе;",
              "Автоматическое изменение производительности при изменении водоразбора",
              "Чередование насосов для равномерного износа и&nbsp;увеличения ресурса",
            ],
          },
        ],
      },
      {
        name: "Дополнительные опции",
        data: [
          {
            type: "list",
            data: [
              "Диспетчеризация сухими контактами;",
              "Встроенный АВР;",
              "Устройства плавного пуска;",
              "Диспетчеризация по&nbsp;интерфейсу RS485.",
            ],
          },
        ],
      },
    ],
  },
};
