import { IMAGES_PATH } from "config/path";

const features = {
  features: {
    title: "Components of the world’s leading brands",
    label: "distinctive features of our cabinets",
    about: ["Certified components", "We work only with trusted suppliers"],
    content: [
      {
        image: {
          src: `${IMAGES_PATH}/pages/product/switching-equipment/1.png`,
        },
        features: [
          "wide customization options for various station configurations",
          "control of pump operating time and their periodic alternation for the purpose of uniform wear",
          "provides tracking of emergencies and protection of equipment",
        ],
        about:
          "Certified for compliance with the requirements of the Technical Regulations of the Customs Union for electromagnetic compatibility and safety of machinery and equipment.",
        name: "Dedicated programmable controller 1",
      },
      {
        image: {
          src: `${IMAGES_PATH}/pages/product/switching-equipment/2.png`,
        },
        features: [
          "collection of information and control of terminal devices by means of built-in relays;",
          "the presence of inputs for connecting limit switches according to the NAMUR scheme (for monitoring short-circuit and open-circuit)",
          "information from inputs and relay control commands are transmitted via industrial MODBUS RTU interface with RS-485 physical layer",
        ],
        about:
          "Certified for compliance with the requirements of the Technical Regulations of the Customs Union for electromagnetic compatibility and safety of machinery and equipment.",
        name: "Dedicated programmable controller 2",
      },
      {
        image: {
          src: `${IMAGES_PATH}/pages/product/switching-equipment/3.png`,
        },
        features: [
          "input of analog values from industrial interfaces 0-20mA, 0-10V, discrete input",
          "control of end devices via built-in relays",
          "Information from inputs, information for outputs and relay control commands are transmitted via the industrial MODBUS RTU interface with an RS-485 physical layer.",
        ],
        about:
          "Certified for compliance with the requirements of the Technical Regulations of the Customs Union for electromagnetic compatibility and safety of machinery and equipment..",
        name: "Dedicated programmable controller 3",
      },
    ],
  },
};

export default features;
