export default {
  id: "nevinnomyssky-azot",
  name: "Невинномысский Азот",
  location: "г. Невинномысск",
  year: 2018,

  page: {
    facility: {
      about: [
        {
          title: "В&nbsp;рамках работы по&nbsp;проекту мы&nbsp;выполнили",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Монтаж автоматической насосной станции повышения давления;</li>
                  <li>Проверка и&nbsp;запуск в&nbsp;эксплуатацию оборудования автоматического пожаротушения.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "facility-about.webp",
          },
        },
        {
          title: "О&nbsp;заказчике",
          content: [
            {
              type: "html",
              data: `
                <p>АО&nbsp;&laquo;Невинномысский Азот&raquo;&nbsp;&mdash; крупнейшее на&nbsp;Юге России предприятие химической промышленности, выпускающее широкую номенклатуру минеральных удобрений и&nbsp;продуктов органического синтеза, реализуемых как на&nbsp;внутреннем рынке, так и&nbsp;на&nbsp;экспорт.</p>
                <p>АО&nbsp;&laquo;Невинномысский Азот&raquo; располагает единственными в&nbsp;России установками по&nbsp;производству меламина, метилацетата, уксусной кислоты высокой чистоты.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}],
    },
  },
};
