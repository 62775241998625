export default {
  id: "grand-master",
  name: "Grand Master",
  location: "Moscow",
  year: 2019,

  page: {
    facility: {
      gallery: [{}, {}, {}],
      about: [
        {
          title: "As part of the work on the project, we have made",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Installation of equipment on the site;</li>
                  <li>The work on connecting and setting up pressure boosting pumping stations, as well as commissioning of equipment in compliance with all requirements.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "about-project.webp",
          },
        },
        {
          title: "About the client",
          content: [
            {
              type: "html",
              data: `
                <p>MIE and Grand Master appliances is sold in all major cities in Russia, including the company’s branded stores, in chain stores of household appliances and electronics, and online hypermarkets.</p>
                <p>Grand Master represents an extensive network of authorized service centers that provide warranty and post-warranty maintenance of equipment, supplies replacement parts and accessories.</p>
              `,
            },
          ],
        },
      ],
    },
  },
};
