import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ContactsMap from "./ContactsMap";

const maps = [
  {
    id: "office",
    center: {
      lat: 47.239078,
      lng: 39.687497,
    },
  },
  {
    id: "storage",
    center: {
      lat: 47.273991,
      lng: 39.685659,
    },
  },
];

class ContactsMaps extends Component {
  static propTypes = {
    t: PropTypes.func,
  };

  render() {
    const { t } = this.props;

    return (
      <section className="maps">
        <div className="maps__inner">
          <ul className="maps__list list row">
            {maps.map((map, i) => (
              <ContactsMap
                key={i}
                title={t(`contacts:contacts.addresses.${map.id}.name`)}
                address={t(`contacts:contacts.addresses.${map.id}.label`)}
                center={map.center}
                className="col-12 col-md-6"
              />
            ))}
          </ul>
        </div>
      </section>
    );
  }
}

export default withTranslation()(ContactsMaps);
