import React from "react";
import PropTypes from "prop-types";
import FooterNav from "./FooterNav";
import { Copyright } from "components/layouts";
import { withTranslation } from "react-i18next";
import { AppearOnScroll } from "components/common";
import { buildPathToProduct } from "routes/product";
import { buildPathToDocumentation } from "routes/documentation";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Divider from "components/common/Divider/Divider";
import SlideUpOnScroll from "components/common/RevaelOnScroll/SlideUpOnScroll";

const documentations = [
  {
    id: "technical-documentation",
    name: "Техническая документация",
    subcategories: [
      {
        name: "Шкафы управления",
        documents: [],
      },
      {
        name: "Шкафы управления",
        documents: [],
      },
    ],
  },
  {
    id: "product-catalogues",
    name: "Каталоги продукции",
    documents: [],
  },
  {
    id: "certificates",
    name: "Сертификаты",
    documents: [],
  },
];

const contactsPrimary = ["phone", "mail"];

function Footer({ renderHeaderInner }) {
  const { t } = useTranslation();

  const navs = useMemo(
    () => [
      {
        title: t("main-nav-items.products"),
        navItems: [
          {
            label: t("products-nav-categories.electronics"),
            link: buildPathToProduct("electronics"),
          },
          {
            label: t("products-nav-categories.control-cabinets"),
            link: buildPathToProduct("control-cabinets"),
          },
          {
            label: t("products-nav-categories.pumping-stations"),
            link: buildPathToProduct("pumping-stations"),
          },
          {
            label: t("products-nav-categories.block-containers"),
            link: buildPathToProduct("block-containers"),
          },
        ],
      },
      {
        title: t("main-nav-items.documentation"),
        navItems: [
          {
            id: "technical-documentation",
            label: t("documentation-nav-items.technical-documentation"),
            link: buildPathToDocumentation("technical-documentation"),
          },
          {
            id: "product-catalogs",
            label: t("documentation-nav-items.product-catalogs"),
            link: buildPathToDocumentation("product-catalogs"),
          },
          {
            id: "certificates",
            label: t("documentation-nav-items.certificates"),
            link: buildPathToDocumentation("certificates"),
          },
        ],
      },
    ],
    [t]
  );

  return (
    <>
      <Divider />
      <footer className="footer">
        <div className="footer__container container">
          <div className="footer__inner">
            <div className="footer__header">
              {renderHeaderInner ? (
                renderHeaderInner()
              ) : (
                <SlideUpOnScroll>
                  <h2 className="footer__title title">{t("footer.title")}</h2>
                </SlideUpOnScroll>
              )}
            </div>
            <div className="footer__body">
              <div className="footer__main row">
                <div className="footer__navs order-1 order-xl-0 col-12 col-xl-6 row">
                  {navs.map((nav, i) => (
                    <FooterNav
                      key={i}
                      title={nav.title}
                      navItems={nav.navItems}
                      className="footer__nav col-6 col-xl-12"
                    />
                  ))}
                </div>
                <div
                  className="footer__company-info footer-company-info order-0 order-xl-1 col-12 col-xl-6 row"
                  itemScope=""
                  itemType="http://schema.org/ElectronicsStore"
                >
                  <div className="footer-company-info__contacts col-12 col-sm-6 col-xl-12">
                    <ul className="footer-company-info__contacts-list list row">
                      {contactsPrimary.map((contact, i) => (
                        <li
                          key={i}
                          className="footer-company-info__contact contact col-12 col-xl-6"
                        >
                          <a
                            href={t(`${contact}.href`)}
                            className="contact__content contact__content_large footer-company-info__contact-link link link_hover-underline"
                          >
                            <AppearOnScroll>
                              <span className="span">
                                {t(`${contact}.label`)}
                              </span>
                            </AppearOnScroll>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="footer-company-info__general col-12 col-sm-6 col-xl-12">
                    <div className="contact">
                      <AppearOnScroll tag="p" className="contact__label">
                        <span className="span">{t("work-schedule.name")}</span>
                      </AppearOnScroll>
                      <AppearOnScroll
                        tag="time"
                        className="contact__content footer__contact contact__content_large"
                      >
                        <span className="span">{t("work-schedule.label")}</span>
                      </AppearOnScroll>
                    </div>
                  </div>
                </div>
              </div>
              <Copyright
                className="footer__copyright"
                leftClassName="col-12 col-sm-6"
                rightClassName="col-12 col-sm-6"
                innerPadding={false}
              />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  renderHeaderInner: PropTypes.func,
};

export default Footer;
