import electronics from "./electronics";
import controlCabinets from "./controlCabinets";
import pumpingStations from "./pumpingStations";
import blockContainers from "./blockContainers";

const products = [
  controlCabinets,
  pumpingStations,
  electronics,
  blockContainers,
];

export default products;
