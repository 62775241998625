import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useSafeContext,
  createSafeConsumer,
  createSafeContext,
} from "./helpers";

import debounce from "lodash/debounce";

const Context = createSafeContext();

export const useBrowserInterfaceVisibility = () => useSafeContext(Context);
export const BrowserInterfaceVisibilityConsumer = createSafeConsumer(Context);

/**
 * Проверяет видимость адресной строки
 */
export const BrowserInterfaceVisibilityProvider = ({ children }) => {
  const [height, _setHeight] = useState(0);
  const [lastDetectedHeight, _setLastDetectedHeight] = useState(0);
  const heightRef = useRef(height);

  const setHeight = useCallback((newHeight) => {
    _setHeight((heightRef.current = newHeight));
    _setLastDetectedHeight((oldLastDetectedHeight) => {
      if (newHeight !== 0 && oldLastDetectedHeight !== newHeight) {
        return newHeight;
      }

      return oldLastDetectedHeight;
    });
  }, []);
  const detectorElRef = useRef(null);

  useEffect(() => {
    const detectorEl = detectorElRef.current;

    const update = () => {
      const height = detectorEl.offsetHeight - window.innerHeight;

      if (heightRef.current !== height) {
        setHeight(height);
      }
    };

    update();

    const windowResizeHandler = debounce(update, 50);

    window.addEventListener("resize", windowResizeHandler);

    return () => {
      window.removeEventListener("resize", windowResizeHandler);
    };
  }, [setHeight]);

  const value = useMemo(
    () => ({
      height,
      lastDetectedHeight,
      isVisible: height !== 0,
    }),
    [height, lastDetectedHeight]
  );

  return (
    <Context.Provider value={value}>
      <div
        ref={detectorElRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          pointerEvents: "none",
          zIndex: -1,
        }}
        className="address-bar-visibility"
      />
      {children}
    </Context.Provider>
  );
};
