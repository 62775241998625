import { AppColor } from "config";
import { IMAGES_PATH } from "config/path";
import controllers from "./controllers";
import modules from "./modules";
import panels from "./panels";
import repeaters from "./repeaters";

const electronics = {
  id: "electronics",
  name: "Электроника",
  image: {
    home: {},
  },
  color: AppColor.YELLOW,
  pageContent: {
    product: {
      advantages: {
        title: "Преимущества\nнашей электроники",
        slides: [
          {
            label: "Безопасность",
            content: {
              body: [
                "детали для сборки насосных станций и шкафов управления отвечают всем стандартам качества и безопасности",
              ],
              footer: "ГОСТ 12.3.002",
            },
          },
          {
            label: "Гарантия",
            content: {
              body: [
                "Наши строители при возведении зданий руководствуются чертежами. После окончания строительства выполняется проверка соответствия выполненных работ проектной документации.",
                "Мы гарантируем высокое качество наших построек, так как контролируем все этапы производства и монтажа.",
              ],
              footer: "Гарантия 1 год",
            },
          },
        ],
      },
      gallery: [{}, {}, {}, {}, {}],
    },
  },
  navThumbnail: [
    {
      image: {
        src: `${IMAGES_PATH}/products/electronics/nav-thumbnail.jpg`,
        alt: "",
      },
    },
  ],
  // subcategories: [controllers, modules, panels, repeaters],
  subcategories: [controllers, repeaters],
};

export default electronics;
