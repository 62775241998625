import React, { useContext, useEffect, useRef } from "react";
import AboutHero from "./AboutHero/AboutHero";
import AboutAdvantages from "./AboutAdvantages/AboutAdvantages";
import AboutCredentials from "./AboutCredentials/AboutCredentials";
import AboutPartners from "./AboutPartners/AboutPartners";
import AboutManufacture from "./AboutManufacture/AboutManufacture";
import { Advantages, Footer } from "components/layouts";

import aboutPageData from "data/pages/about";
import gsap from "gsap";
import { AppPageTransitionContext } from "App";
import { useTranslation } from "react-i18next";
import { isInView } from "utils/dom";

const About = (props) => {
  const { t } = useTranslation();

  // const { lead, advantages, credentials, partners, manufacture } =
  // aboutPageData;

  const {
    enableScroll,
    disableScroll,
    getHeaderAppearTimeline,
    getOverlayDisappearTween,
  } = useContext(AppPageTransitionContext);
  const heroRef = useRef(null);
  const advantagesRef = useRef(null);

  useEffect(() => {
    const heroElem = heroRef.current;
    const advantagesElem = advantagesRef.current;

    const timeline = gsap.timeline();
    const ourPartners = document.querySelector(".our-partners");
    const ourPartnersCardsEl = ourPartners.querySelector(".cards");
    const ourPartnersCardTargetElms = ourPartnersCardsEl.querySelectorAll(
      ".container_overflow-hidden > *"
    );

    const titleElem = heroElem.querySelector(".about-hero__title");
    const descriptionElem = heroElem.querySelector(".about-hero__subtitle");

    const advantagesAsideTargetElms = advantagesElem.querySelectorAll(
      ".advantages__aside .slide-up-on-scroll__target > *, .advantages__title-container > *, .advantages__subtitle-container > *"
    );

    const advantagesActiveSlideTargetElms = advantagesElem.querySelectorAll(
      ".advantages-slider-slide_active .slide-up-on-scroll__target > *"
    );

    timeline.add(() => {
      window.scrollTo(0, 0);
      disableScroll();
    });

    timeline.add(getOverlayDisappearTween(), "+=0.2");

    timeline.add(enableScroll);

    timeline.from(heroElem, {
      scaleX: 0,
      transformOrigin: "left",
      duration: 0.6,
    });

    timeline.from([titleElem, descriptionElem], {
      yPercent: 100,
      stagger: 0.05,
    });

    timeline.add(getHeaderAppearTimeline(), ">");

    timeline.from(
      advantagesAsideTargetElms,
      {
        yPercent: 100,
        stagger: 0.05,
      },
      "<"
    );

    timeline.from(
      advantagesActiveSlideTargetElms,
      {
        yPercent: 100,
        stagger: 0.05,
      },
      "<"
    );

    // timeline.from(
    //   advantagesHeaderTargetElms,
    //   {
    //     yPercent: 100,
    //     stagger: 0.05,
    //   },
    //   "<"
    // );

    timeline.set(ourPartners.querySelectorAll(".appear-on-scroll"), {
      overflow: "hidden",
    });

    const ourPartnersTitleEl = ourPartners.querySelector(
      ".our-partners__title"
    );
    const ourPartnersDescriptionEl = ourPartners.querySelector(
      ".our-partners__caption"
    );

    timeline.fromTo(
      [ourPartnersTitleEl, ourPartnersDescriptionEl],
      {
        yPercent: 100,
      },
      {
        yPercent: 0,
        stagger: 0.05,
      },
      "<"
    );

    timeline.from(
      ourPartnersCardsEl,
      {
        opacity: 0,
      },
      "<"
    );

    const cardTargetsElmsInView = [...ourPartnersCardTargetElms].filter(
      isInView
    );

    timeline.from(cardTargetsElmsInView, {
      yPercent: 100,
      stagger: 0.05,
    });

    return () => {
      timeline.kill();
    };
  }, [getHeaderAppearTimeline, enableScroll, getOverlayDisappearTween]);
  return (
    <>
      <AboutHero ref={heroRef} />
      <Advantages
        innerRef={advantagesRef}
        title={t("about:advantages.title")}
        subtitle={t("about:advantages.subtitle")}
        slides={t("about:advantages.slides", { returnObjects: true })}
        className="about-our-advantages"
        asideClassName="col-12 col-md-4 col-xl-3"
        mainClassName="col-12 col-md-8 col-xl-9"
      />
      {/* <AboutCredentials {...credentials} /> */}
      <AboutPartners />
      <AboutManufacture />
      <Footer />
    </>
  );
};

export default About;
