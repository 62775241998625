import React, {
  Component,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect, useSelector } from "react-redux";

import HomeHero from "./HomeHero/HomeHero";
import HomeOurFacilities from "./HomeOurFacilities/HomeOurFacilities";
import HomePartners from "./HomePartners/HomePartners";
import HomeReviews from "./HomeReviews/HomeReviews";
import HomeFooter from "./HomeFooter/HomeFooter";
import { getFacilities } from "store/selectors/data";
import { AppPageTransitionContext } from "App";
import { BodyScrollContext } from "App";
import gsap from "gsap";
import { BrowserInterfaceVisibilityProvider } from "contexts/BrowserInterfaceVisibility";
import { useTranslation } from "react-i18next";

const Home = (props) => {
  const [activeProductIndex, setActiveProductIndex] = useState(0);
  const { t } = useTranslation("products");
  const categories = t("categories", { returnObjects: true });

  // console.log(categories);
  // const productsCategories = useSelector(getProducts);
  const facilities = useSelector(getFacilities);
  const heroRef = useRef(null);

  const { enableScroll, disableScroll } = useContext(BodyScrollContext);

  const { getHeaderAppearTimeline, getOverlayDisappearTween } = useContext(
    AppPageTransitionContext
  );

  const timelineRef = useRef(null);

  useEffect(() => {
    const timeline = (timelineRef.current = gsap.timeline());
    const heroElem = heroRef.current;
    const heroFirstBackgroundSlideImageWrapper = heroElem.querySelector(
      ".home-hero-background-slider-slide__image-container"
    );
    const heroTransitionElems = heroElem.querySelectorAll(
      `
      .home-hero-nav__link-label,
      .home-hero-title-link__inner,
      .home-hero-sub-nav__link,
      .home-hero__description,
      .pagination__inner,
      .next-to-btn__dir-container,
      .next-to-btn__button-container
      `
    );

    console.log("init appear animation");

    gsap.killTweensOf(heroTransitionElems);

    timeline.add(
      getOverlayDisappearTween({
        to: { duration: 0 },
      })
    );

    timeline.add(() => {
      window.scrollTo(0, 0);
      disableScroll();
    });

    timeline.from(heroFirstBackgroundSlideImageWrapper, {
      scale: 1.3,
      xPercent: 30,
      yPercent: 5,
      duration: 0.6,
      opacity: 0,
      ease: "ease-out",
    });

    timeline.from(heroTransitionElems, {
      yPercent: 100,
      duration: 0.6,
      stagger: 0.05,
      ease: "ease-out",
    });

    return () => {
      timeline.kill();
    };
  }, [enableScroll, disableScroll, getOverlayDisappearTween]);

  const changeProduct = useCallback((index) => {
    setActiveProductIndex(index);
  }, []);

  return (
    <>
      <BrowserInterfaceVisibilityProvider>
        <HomeHero
          ref={heroRef}
          productsCategories={categories}
          changeProduct={changeProduct}
          activeProductIndex={activeProductIndex}
        />
      </BrowserInterfaceVisibilityProvider>
      <HomeOurFacilities facilities={facilities} />
      <HomePartners />
      <HomeReviews />
      <HomeFooter />
    </>
  );
};

export default memo(Home);
