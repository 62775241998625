export default {
  id: "status-a-1-1",
  name: "STATUS-A-1-1",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "html",
          data: `
            <p>The signal repeater is designed for repeating, converting and multiplying analog signals, as well as preventing interference on the line. It can also be used as an analog input protection.</p>
          `,
        },
      ],
    ],
    specifications: [
      {
        name: "Сharacteristics",
        data: [
          {
            type: "html",
            data: `
              <ul>
                <li>Repetition of an analog signal from 0 to 5 Volts and from 0 to 10 Volts;</li>
                <li>Repetition of an analog signal from 0 to 20mA and from 4 to 20mA;</li>
                <li>Signal conversion from 0-10V to 0-20mA;</li>
                <li>Signal conversion from 0-20mA to 0-10V;</li>
                <li>Multiplication of a current signal when using an assembly of two or more devices.</li>
              </ul>
            `,
          },
        ],
      },
      {
        name: "Distinctive features",
        data: [
          {
            type: "html",
            data: `
              <ul>
                <li>The device is designed for repeating, converting and multiplying analog signals;</li>
                <li>Flexible configuration options for the device;</li>
                <li>Possibility of the repetition of 0-5V and 0-10V signals;</li>
                <li>Possibility of repetition of signals 0-20mA and 4-20mA;</li>
                <li>Built-in line noise protection.</li>
              </ul>
            `,
          },
        ],
      },
    ],
  },
};
