export default {
  id: "pao-nlmk",
  name: "PJSC NLMK",
  location: "Lipetsk",
  year: 2020,

  page: {
    facility: {
      about: [
        {
          title: "As part of the work on the project, we have made",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Delivery of pressure boosting pumping stations to the client’s site;</li>
                  <li>Installation and connection of the equipment;</li>
                  <li>Staff training on equipment operation.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "about-project.webp",
          },
        },
        {
          title: "About the client",
          content: [
            {
              type: "html",
              data: `
                <p>NLMK is a plant with a full metallurgical cycle: from the production of raw materials for iron and steel smelting to the final product — flat rolled metal with high value-added. NLMK’s high-quality metal products are used in various strategically important sectors of the economy, from construction and mechanical engineering to the production of energy equipment and large-diameter pipes.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}],
    },
  },
};
