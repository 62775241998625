import { AppColor } from "config";
import hydroGc from "./hydro-gc";
import hydroGi from "./hydro-gi";
import hydroGl from "./hydro-gl";
import hydroGie from "./hydro-gie";

const subcategory = {
  id: "water-supply",
  name: "Water supply",
  color: AppColor.BLUE,
  image: {
    home: {},
  },
  products: [hydroGc, hydroGi, hydroGie, hydroGl],
};

export default subcategory;
