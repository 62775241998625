import waterSupply from "./waterSupply";
import firefighting from "./firefighting";
import drainage from "./drainage";
import { ChartBarLvl } from "components/pages/Product/UseCases/UseCasesStatisticChartBar";
import { IMAGES_PATH } from "config/path";
import { AppColor } from "config";

export const subcategory = {
  [waterSupply.id]: waterSupply,
  [firefighting.id]: firefighting,
  [drainage.id]: drainage,
};

const pumpingStations = {
  id: "pumping-stations",
  name: "Насосные станции",
  color: AppColor.BLUE,
  pageContent: {
    product: {
      advantages: {
        title: "Преимущества\nнаших насосных станций",
        slides: [
          {
            label: "Безопасность",
            content: {
              body: [
                "Насосные станции относятся к 1-ой категории надежности по надежности действия, электроснабжения и обеспеченности водоснабжения (СНиП 2.04.02-84).",
                "Все оборудование и приборы должны иметь сертификат безопасности.",
              ],
              footer: "ГОСТ 1.2-2009",
            },
          },
          {
            label: "Гарантия",
            content: {
              body: [
                "На все насосы и насосное оборудование устанавливается гарантийный срок.",
                "Если в течение гарантийного срока эксплуатации насос вышел из строя по вине Изготовителя, предоставляется бесплатный гарантийный ремонт насоса согласно условиям и порядку гарантийного обслуживания насосов.",
              ],
              footer: "Гарантия 1 год",
            },
          },
          {
            label: "Детали",
            content: {
              body: [
                "Работаем только с проверенными поставщиками",
                "детали для сборки насосных станций и шкафов управления отвечают всем стандартам качества и безопасности",
              ],
              footer: "Гарантия 1 год",
            },
          },
        ],
      },
      useCases: {
        title: "Насосные станции Hydro G",
        description: [
          "Насосные станции Hydro G включают в себя насосы GRUNDFOS, всасывающий и напорный трубопроводы из 0нержавеющей стали, вентили и обратные клапаны, шкаф управления Control G, датчики давления и манометры",
          [
            "системы орошения",
            "пожаротушение",
            "повышения (поддержания) давления в системах холодного и горячего водоснабжения",
          ],
        ],
        table: {
          head: [
            "Административные здания",
            "Производственные здания",
            "Жилые здания",
            "Канализационные системы",
            "Системы водозабора и водоотведения",
            "Циркулярные насосные станции",
          ],
          body: [
            {
              label: "Hydro GC",
              data: [
                ChartBarLvl.Unacceptable,
                ChartBarLvl.Well,
                ChartBarLvl.Good,
                ChartBarLvl.Acceptable,
                ChartBarLvl.Good,
                ChartBarLvl.Unacceptable,
              ],
            },
            {
              label: "Hydro GI",
              data: [
                ChartBarLvl.Good,
                ChartBarLvl.Acceptable,
                ChartBarLvl.Unacceptable,
                ChartBarLvl.Acceptable,
                ChartBarLvl.Good,
                ChartBarLvl.Good,
              ],
            },
            {
              label: "Hydro GL",
              data: [
                ChartBarLvl.Acceptable,
                ChartBarLvl.Excellent,
                ChartBarLvl.Well,
                ChartBarLvl.Excellent,
                ChartBarLvl.Well,
                ChartBarLvl.Good,
              ],
            },
            {
              label: "Hydro GF",
              data: [
                ChartBarLvl.Well,
                ChartBarLvl.Good,
                ChartBarLvl.Acceptable,
                ChartBarLvl.Good,
                ChartBarLvl.Good,
                ChartBarLvl.Well,
              ],
            },
            {
              label: "Hydro GFS",
              data: [
                ChartBarLvl.Good,
                ChartBarLvl.Unacceptable,
                ChartBarLvl.Acceptable,
                ChartBarLvl.Good,
                ChartBarLvl.Good,
                ChartBarLvl.Good,
              ],
            },
            {
              label: "Hydro GFY",
              data: [
                ChartBarLvl.Acceptable,
                ChartBarLvl.Acceptable,
                ChartBarLvl.Unacceptable,
                ChartBarLvl.Well,
                ChartBarLvl.Excellent,
                ChartBarLvl.Well,
              ],
            },
            {
              label: "Hydro GK",
              data: [
                ChartBarLvl.Acceptable,
                ChartBarLvl.Well,
                ChartBarLvl.Good,
                ChartBarLvl.Excellent,
                ChartBarLvl.Good,
                ChartBarLvl.Acceptable,
              ],
            },
            {
              label: "Hydro GKS",
              data: [
                ChartBarLvl.Acceptable,
                ChartBarLvl.Excellent,
                ChartBarLvl.Excellent,
                ChartBarLvl.Well,
                ChartBarLvl.Excellent,
                ChartBarLvl.Good,
              ],
            },
          ],
        },
      },
      gallery: [{}, {}, {}, {}, {}],
    },
  },
  navThumbnail: {
    image: {
      src: `${IMAGES_PATH}/products/pumping-stations/nav-thumbnail-1.jpg`,
      alt: "",
    },
  },
  subcategories: [waterSupply, firefighting, drainage],
};

export default pumpingStations;
