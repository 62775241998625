import { useTranslation } from "react-i18next";

const contacts = [
  "phone",
  "mail",
  // {
  //   href: "tel:+78633089090",
  //   itemProp: "telephone",
  //   label: "8 (863) 308 90 90",
  // },
  // {
  //   href: "mailto:info@globus-it.ru",
  //   itemProp: "email",
  //   label: "info@globus-it.ru",
  // },
];

function MenuFooter() {
  const { t } = useTranslation();

  return (
    <div className="menu__footer">
      <ul className="menu__contacts list">
        {contacts.map((contact, i) => (
          <li key={i} className="menu__contact contact">
            <a
              className="contact__content contact__content_medium link"
              href={t(`${contact}.href`)}
              itemProp={contact.itemProp}
            >
              {t(`${contact}.label`)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MenuFooter;
