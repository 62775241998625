import { DOCUMENTS_PATH } from "config/path";
import { getProductManualSrc } from "utils/path";

export default {
  id: "status-iv-c",
  name: "STATUS-IV",
  type: "",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "html",
          data: "<p>designed to create automation systems for technological processes associated with &nbsp; maintaining a given value of a technological parameter, with&nbsp;the ability to remotely monitor, control and&nbsp;change parameters.</p>",
        },
      ],

      [
        {
          type: "html",
          data: `
            <ul>
              <li>cascade algorithm (step maintenance of the controlled parameter);</li>
              <li>one frequency converter (stepless maintenance of the controlled parameter);</li>
              <li>float.</li>
            </ul>
          `,
        },
      ],
    ],
    specifications: [
      {
        name: "Applications",
        data: [
          {
            type: "html",
            data: `
              <ul>
                <li>Lack of water;</li>
                <li>The analog dry running sensor breakout;</li>
                <li>The analog pressure sensor breakout;</li>
                <li>Communication failure between the controller and the frequency converter;</li>
                <li>Power supply accidents;</li>
                <li>Prohibition of station operation from the dispatching console, fire extinguishing station or other control systems;</li>
                <li>Protection of electric motors (automatic machines, thermal relays or other methods, depending on the automatic system configuration).</li>
              </ul>
            `,
          },
        ],
      },
      {
        name: "Distinctive features",
        data: [
          {
            type: "html",
            data: `
              <ul>
                <li>Control of automatic pumping units;</li>
                <li>Provides tracking of emergencies and equipment protection;</li>
                <li>Monitoring of pump operating time and their periodic alternation for the purpose of uniform wear;</li>
                <li>Massive opportunities of settings for various station configurations.</li>
              </ul>
            `,
          },
        ],
      },
      {
        name: "Documentation",
        data: [
          {
            type: "cards",
            data: [
              {
                type: "documentation",
                data: {
                  caption: "manual",
                  title: "STATUS-IV",
                  file: getProductManualSrc({
                    categoryId: "electronics",
                    subcategoryId: "controllers",
                    productId: "status-iv",
                  }),
                },
              },
            ],
          },
        ],
      },
    ],
  },
};
