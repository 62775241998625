export default {
  id: "hydro-gl",
  name: "Hydro GL",
  type: "стандартный",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data: "предназначены для перекачивания, повышения и&nbsp;поддержания давления в&nbsp;системах водоснабжения&nbsp;&mdash; промышленности, ЖКХ, сельского хозяйства.",
        },
      ],
    ],
    specifications: [
      {
        name: "Подробное описание",
        data: [
          [
            {
              type: "text",
              data: [
                "Установки стандартного исполнения имеют до&nbsp;десяти одинаковых насосов, соединенных параллельно и&nbsp;смонтированных на&nbsp;одной общей раме основания, с&nbsp;необходимым комплектом запорной, трубопроводной арматуры, КИПиА и&nbsp;шкафом управления.",
                "Всасывающий и&nbsp;напорный коллектор, а&nbsp;также станина выполнены из&nbsp;нержавеющей стали AISI 304 (AISI 304L).",
                "На&nbsp;заводе производятся гидравлические (опрессовка) и&nbsp;электрические испытания.",
                "Все установки поставляются полностью подготовленными к&nbsp;подключению и&nbsp;вводу в&nbsp;эксплуатацию.",
              ],
            },
          ],
        ],
      },
      {
        name: "Область применения",
        data: [
          [
            {
              type: "list",
              data: [
                "Системы повышения давления",
                "Водоснабжение зданий, спортивных объектов",
                "Ирригационные системы",
              ],
            },
            {
              type: "list",
              data: [
                "Водоснабжение зданий, спортивных объектов",
                "Системы орошения",
                "Станции очистки питьевой воды",
              ],
            },
          ],
        ],
      },
      {
        name: "Основные функции",
        data: [
          {
            type: "list",
            data: [
              "Поддержание заданного давления в&nbsp;выходном трубопроводе;",
              "Чередование насосов для равномерного износа и&nbsp;увеличения ресурса",
              "Автоматическое изменение производительности при изменении водоразбора",
            ],
          },
        ],
      },
      {
        name: "Отличительные особенности",
        data: [
          {
            type: "list",
            data: [
              "Точное поддержание заданного давления",
              "Автоматическое возобновление работы после устранения неисправности",
              "Защита насосов от&nbsp;работы без воды",
              "Защита от&nbsp;обрыва, асимметрии и&nbsp;неправильного чередования фаз",
              "Защита насосов от&nbsp;перегрузки по&nbsp;току и&nbsp;короткого замыкания",
              "Исключение кавитации при нулевом водопотреблении",
              "Повышенная отказоустойчивость",
              "Низкая стоимость, по&nbsp;сравнению с&nbsp;оригинальными станциями ABS, Grundfos, KSB, Wilo,FLYGT",
            ],
          },
        ],
      },
      {
        name: "Дополнительные опции",
        data: [
          {
            type: "list",
            data: [
              "Диспетчеризация сухими контактами;",
              "Сенсорная панель операторае",
              "Диспетчеризация по&nbsp;интерфейсу RS485 или RS232",
            ],
          },
        ],
      },
    ],
  },
};
