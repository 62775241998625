import { AppColor } from "config/index";

import controlGf from "./control-gf";


const subcategory = {
  id: "firefighting",
  name: "Пожаротушение",
  color: AppColor.DARK_RED,
  products: [controlGf],
};

export default subcategory;
