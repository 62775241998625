export default {
  id: "bc-12192",
  name: "BC-12192",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data: "Панельные блок-контейнеры предназначены для использования в диапазоне температур от −60С° до +40С°, применяются для размещения и хранения оборудования. В стандартную комплектацию входит:",
        },
      ],
      [
        {
          type: "list",
          data: [
            "Основное и аварийное освещение;",
            "Система пожаротушения;",
            "Электрообогрев;",
            "Система газовыхлопа;",
            "Система пожарной сигнализации;",
            "Комплект ручного пожаротушения (огнетушитель типа ОУ).",
          ],
        },
      ],
    ],
    specifications: [
      {
        name: "Характеристики",
        data: [
          {
            type: "list",
            data: [
              "Размер контейнера 12192×2438×2591",
              "Освещение;",
              "Электрообогрев;",
              "Шкаф собственных нужд;",
              "Венциляционные жалюзи с электроприводом;",
              "Силового металлического каркас из хладостойкой и износостойкой стали;",
              "Стены и потолок внутри выполнены из сэндвич-панелей толщиной 100 мм;",
              "Отверстия для ввода-вывода кабелей.",
            ],
          },
        ],
      },
    ],
  },
};
