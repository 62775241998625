import { AppColor } from "config";
import { IMAGES_PATH } from "config/path";
import controllers from "./controllers";
import repeaters from "./repeaters";

const electronics = {
  id: "electronics",
  name: "Electronics",
  image: {
    home: {},
  },
  color: AppColor.YELLOW,
  pageContent: {
    product: {
      advantages: {
        title: "Advantages\nour electronics",
        slides: [
          {
            label: "Safety",
            content: {
              body: [
                "parts for assembling pumping stations and control cabinets meet all quality and safety standards",
              ],
              footer: "GOST 12.3.002",
            },
          },
          {
            label: "Warranty",
            content: {
              body: [
                "When constructing buildings, our builders are guided by drawings. After the completion of construction, the compliance of the work performed with the design documentation is checked.",
                "We guarantee the high quality of our buildings, as we control all stages of production and installation.",
              ],
              footer: "1 year warranty",
            },
          },
        ],
      },
      gallery: [{}, {}, {}, {}, {}],
    },
  },
  navThumbnail: [
    {
      image: {
        src: `${IMAGES_PATH}/products/electronics/nav-thumbnail.jpg`,
        alt: "",
      },
    },
  ],
  subcategories: [controllers, repeaters],
};

export default electronics;
