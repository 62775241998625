import { useContext, createContext } from "react";

const defaultValue = NaN;

export function createSafeContext() {
  return createContext(defaultValue);
}

export function useSafeContext(TheContext) {
  const value = useContext(TheContext);

  if (value === defaultValue) {
    throw new Error("no value provided for context");
  }

  return value;
}

export function createSafeConsumer(TheContext) {
  return ({ children }) => {
    const value = useSafeContext(TheContext);

    return children(value);
  };
}
