export default {
  id: "c-control-gie-sf",
  name: "Control GIe",
  type: "single-frequency",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data: "controls all connected electric motors (pumps). one or two controlled pumps. One electric motor (pump) is controlled by a frequency converter, if there is a lack of capacity, the second, is connected directly to the mains.",
        },
      ],
      [
        {
          type: "list",
          data: [
            "pressure boosting systems;",
            "irrigation systems;",
            "sprinkling system;",
            "drinking water treatment plants.",
            "buildings, sports facilities water supply;",
            "water supply for industrial facilities;",
          ],
        },
      ],
    ],
    specifications: [
      {
        name: "Main functions",
        data: [
          {
            type: "list",
            data: [
              "Protection of pump motors;",
              "Automatic and manual pump control;",
              "Automatic start of main pumps in accordance with control signals;",
              "Automatic start of the backup pump in case of failure or main pump  underperformance for a specified time;",
              "Manual shutdown of automatic start-up of pumps while maintaining the possibility of manual start;",
              "System status indication by means of light indication;",
              'Sound signaling of the "FAILURE" state;',
              "Visual display of the system state and setting the necessary parameters using the operator panel;",
              "System state dispatching using dry contacts (standard) or interface communication line (optional);",
              "Continuous regulation by means of a frequency converter.",
            ],
          },
        ],
      },
      {
        name: "Distinctive features",
        data: [
          {
            type: "list",
            data: [
              'Сonformity with TP TC 004/2011 "About safety of low voltage equipment";',
              'Сonformity with TR CU 020/2011 "Electromagnetic compatibility of technical equipment";',
              "Intuitive interface;",
              "Customization flexibility;",
              "Economical solution for pressure maintenance and circulation systems.",
            ],
          },
        ],
      },
      {
        name: "Additional options",
        data: [
          {
            type: "list",
            data: [
              "Light and sound alarm;",
              "Selection of operating modes;",
              "The ability to customize the required parameters;",
              "Dispatching using dry contacts (standard) or interface communication line (optional);",
            ],
          },
        ],
      },
    ],
  },
};
