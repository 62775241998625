import React from "react";
import classNames from "classnames";
import ContactsHeroItem from "./ContactsHeroItem";
import { withTranslation } from "react-i18next";
import { AppearOnScroll, ViewTransitionItem } from "components/common";
import SlideUpOnScroll from "components/common/RevaelOnScroll/SlideUpOnScroll";

function ContactsHeroMain({ className, t }) {
  const contacts = ["phone", "mail"];

  const workingTime = {
    caption: "режим работы",
    datetime: "Mo-Fr 8:00-17:00",
    itemProp: "openingHours",
    label: "пн-пт 8:00-17:00",
  };

  const address = {
    office: {
      caption: "офис",
      meta: "Ростов-на-Дону\nул. Мечникова, 112, оф.11",
    },
    storage: {
      caption: "склад",
      meta: "Ростов-на-Дону\nул. Вавилова, 65",
    },
  };

  return (
    <div className={classNames("contacts__main contacts-main", className)}>
      <div className="contacts-main__inner row">
        <div className="contacts-main__item col-6 col-lg-12 order-md-3">
          <ContactsHeroItem
            caption={t("work-schedule.name")}
            meta={t("work-schedule.label")}
          />
        </div>

        <div className="contacts-main__item col-6 col-lg-12 order-md-1 order-lg-0">
          <div className="contacts-main__contacts contacts-main-contacts">
            <ul className="contacts-main-contacts__list list">
              {contacts.map((contact, i) => (
                <li
                  key={i}
                  className="contacts-main-contacts__item container_overflow-hidden"
                >
                  <SlideUpOnScroll>
                    <a
                      href={t(`${contact}.href`)}
                      className="contacts-link contacts-link_md link_hover-underline"
                    >
                      {t(`${contact}.label`)}
                    </a>
                  </SlideUpOnScroll>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="contacts-main__item col-6 col-lg-12 order-md-0 order-lg-1">
          <ContactsHeroItem
            caption={t("contacts:contacts.addresses.office.name")}
            meta={t("contacts:contacts.addresses.office.label")}
          />
        </div>

        <div className="contacts-main__item col-6 col-lg-12 order-md-2">
          <ContactsHeroItem
            caption={t("contacts:contacts.addresses.storage.name")}
            meta={t("contacts:contacts.addresses.storage.label")}
          />
        </div>
      </div>
    </div>
  );
}

ContactsHeroMain.propTypes = {};

export default withTranslation()(ContactsHeroMain);
