import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const HeaderContact = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames("header-contact contact", className)}
      itemType="http://schema.org/Organization"
    >
      <a
        href={t(`phone.href`)}
        className="header-contact__content contact__content link"
        itemProp="telephone"
      >
        {t(`phone.label`)}
      </a>
    </div>
  );
};

HeaderContact.propTypes = {
  className: PropTypes.string,
};

export default HeaderContact;
