import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export const ChartBarLvl = {
  Unacceptable: 20,
  Acceptable: 40,
  Good: 60,
  Well: 80,
  Excellent: 100,
};

/**
 * Компонент создает столбчатую диаграмму с данными равными percentage
 *
 * @param {Object} props
 * @param {Object} props.percentage - Процент заполненности столбчатой диаграммы
 */
const UseCasesStatisticChartBar = ({ percentage }) => {
  const { t } = useTranslation();
  let label;

  if (percentage <= ChartBarLvl.Unacceptable) {
    label = "unacceptable";
  } else if (percentage <= ChartBarLvl.Acceptable) {
    label = "acceptable";
  } else if (percentage <= ChartBarLvl.Good) {
    label = "good";
  } else if (percentage <= ChartBarLvl.Well) {
    label = "well";
  } else {
    label = "excellent";
  }

  return (
    <div
      className={classNames(
        "products-use-cases-statistic-chart-bar",
        `products-use-cases-statistic-chart-bar_${label}`
      )}
    >
      <div className="products-use-cases-statistic-chart-bar__label">
        {t(`chart-bar-labels.${label}`)}
      </div>
    </div>
  );
};

UseCasesStatisticChartBar.propTypes = {
  percentage: PropTypes.number,
};

export default UseCasesStatisticChartBar;
