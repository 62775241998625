export default {
  id: "pao-nlmk",
  name: "ПАО НЛМК",
  location: "г. Липецк",
  year: 2020,

  page: {
    facility: {
      about: [
        {
          title: "В&nbsp;рамках работы по&nbsp;проекту мы&nbsp;выполнили",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Доставка насосных станций повышения давления на&nbsp;объект заказчика;</li>
                  <li>Установка и&nbsp;подключение оборудования;</li>
                  <li>Обучение персонала эксплуатации оборудования.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "about-project.webp",
          },
        },
        {
          title: "О&nbsp;заказчике",
          content: [
            {
              type: "html",
              data: `
                <p>НЛМК&nbsp;&mdash; предприятие с&nbsp;полным металлургическим циклом: от&nbsp;производства сырья для выплавки чугуна и&nbsp;стали до&nbsp;конечной продукции&nbsp;&mdash; плоского металлопроката с&nbsp;высокой добавленной стоимостью. Высококачественная металлопродукция НЛМК применяется в&nbsp;различных стратегически важных отраслях экономики, от&nbsp;строительства и&nbsp;машиностроения до&nbsp;производства энергетического оборудования и&nbsp;труб большого диаметра.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}],
    },
  },
};
