export default {
  id: "hydro-gf",
  name: "Hydro GF",
  type: "прямой пуск",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data:
            "предназначены для обеспечения гарантированной подачи воды в&nbsp;систему пожаротушения при возникновении внештатной ситуации",
        },
      ],
      [
        {
          type: "list",
          data: [
            "Надежность работы",
            "Использование качественных материалов для всех узлов оборудования",
            "Возможность эффективного выполнения своих функций даже после длительного простоя",
            "Низкая стоимость\nпо&nbsp;сравнению с&nbsp;оригинальными станциями ABS, Grundfos, KSB, Wilo, FLYGT",
          ],
        },
      ],
    ],
    specifications: [
      {
        name: "Подробное описание",
        data: [
          [
            {
              type: "text",
              data: [
                "Пожарная насосная установка HYDRO GF&nbsp;предназначена для обеспечения гарантированной подачи воды в&nbsp;систему пожаротушения при возникновении внештатной ситуации.",
                "Соответствует всем нормам, правилам, техническим регламентам и&nbsp;СП.",
                "Комплектуется насосная установка различными модулями автоматизации процессов и&nbsp;контроля подачи воды, а&nbsp;также основным и&nbsp;резервным насосом, смонтированными на&nbsp;одной общей раме основания. Всасывающий и напорный коллектор, а&nbsp;также станина выполнены из&nbsp;нержавеющей стали AISI 304 (AISI 304L).",
                "Источниками водоснабжения для них могут являться как пожарные водоемы, резервуары или емкости, так и&nbsp;городская водопроводная сеть.",
                "Насосные установки проходят гидравлические (опрессовка) и&nbsp;электрические испытания.",
                "Все установки поставляются полностью подготовленными к&nbsp;подключению и&nbsp;вводу в&nbsp;эксплуатацию.",
              ],
            },
          ],
        ],
      },
      {
        name: "Область применения",
        data: [
          [
            {
              type: "list",
              data: [
                "Производственные помещения",
                "Складские помещения",
                "Административные и&nbsp;жилые здания",
              ],
            },
            {
              type: "list",
              data: ["Культурные центры", "Социальные учреждения"],
            },
          ],
        ],
      },
      {
        name: "Принцип работы",
        data: [
          {
            type: "list",
            data: ["Пенные", "Водяные"],
          },
        ],
      },
      {
        name: "Тип исполнения",
        data: [
          {
            type: "list",
            data: ["Дренчерные", "Спринклерные", "Спринклерно-дренчерные"],
          },
        ],
      },
      {
        name: "Технические данные",
        data: [
          {
            type: "list",
            data: [
              "Номинальный расход: до&nbsp;1000 м3/ч;",
              "Номинальный напор: до&nbsp;250&nbsp;м;",
              "Питание: 3&times;380&nbsp;В, 50&nbsp;Гц;",
              "Мощность насосов: до&nbsp;355&nbsp;кВт.",
            ],
          },
        ],
      },
      {
        name: "Дополнительные опции",
        data: [
          {
            type: "list",
            data: [
              "Плавный пуск насосов",
              "Управление задвижками",
              "Диспетчеризация по&nbsp;общепромышленным интерфейсам",
            ],
          },
        ],
      },
    ],
  },
};
