export default {
  id: "hydro-gl",
  name: "Hydro GL",
  type: "multi-frequency",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data: "designed for pumping, increasing and maintaining pressure in water supply systems - industry, housing and communal services, agriculture.",
        },
      ],
    ],
    specifications: [
      {
        name: "Main functions",
        data: [
          [
            {
              type: "text",
              data: [
                "Maintaining the set pressure in the outlet pipe;",
                "Alternating pumps for even wear and life-extension;",
                "Automatic change in the number of running pumps when changing the water water draw-off.",
              ],
            },
          ],
        ],
      },
      {
        name: "Distinctive features",
        data: [
          [
            {
              type: "list",
              data: [
                "Precise maintenance of the set pressure;",
                "Automatic restart after troubleshooting process;",
                "Protection of pumps from running without water;",
                "Protection against loss, asymmetry and incorrect phase sequence;",
                "Protection of pumps against overcurrent and short circuit;",
                "Elimination of cavitation at zero water consumption;",
                "Increased resiliency;",
                "Low cost compared to original stations ABS, Grundfos, KSB, Wilo, FLYGT.",
              ],
            },
          ],
        ],
      },
      {
        name: "Additional options",
        data: [
          {
            type: "list",
            data: [
              "Dry contacts dispatching;",
              "Operator touch panel;",
              "Dispatching via RS485 or RS232 interface.",
            ],
          },
        ],
      },
    ],
  },
};
