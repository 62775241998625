export default {
  id: "nevinnomyssky-azot",
  name: "Nevinnomyssky Azot",
  location: "Nevinnomyssk",
  year: 2018,

  page: {
    facility: {
      about: [
        {
          title: "As part of the work on the project, we have made",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Installation of an automatic pressure boosting pumping stations;</li>
                  <li>Testing and commissioning of automatic fire extinguishing equipment.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "facility-about.webp",
          },
        },
        {
          title: "About the client",
          content: [
            {
              type: "html",
              data: `
                <p>JSC «Nevinnomyssky Azot» is the largest chemical industry plant in the South of Russia, producing a wide range of mineral fertilizers and organic chemicals sold both on the domestic market and for export.</p>
                <p>JSC «Nevinnomyssky Azot» is the only one in Russia which has plants for the production of melamine, methyl acetate, acetic acid of high purity.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}],
    },
  },
};
