import common from "./common.json";
import pages from "./pages.json";
import complex from "./complex.json";
import home from "./home.json";
import contacts from "./contacts.json";
import about from "./about.json";
import ourFacilities from "./our-facilities.json";
import documentation from "./documentation.json";
import products from "./products";
import facilities from "./facilities";
import productFeatures from "./product-features";

const locale = {
  common,
  pages,
  complex,
  home,
  contacts,
  about,
  "our-facilities": ourFacilities,
  documentation,
  products,

  facilities,
  "product-features": productFeatures,
};

export default locale;
