import React, {
  Component,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

import FacilityHero from "./FacilityHero/FacilityHero";
import { Footer } from "components/layouts";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { getFacilities } from "store/selectors/data";
import notFoundRoute from "routes/notFound";
import { AppPageTransitionContext } from "App";
import gsap from "gsap";
import { useTranslation } from "react-i18next";

const Facility = () => {
  const { t } = useTranslation();
  const facilities = t("facilities:facilities", { returnObjects: true }); //useSelector(getFacilities);
  const params = useParams();
  const heroRef = useRef();
  const {
    enableScroll,
    disableScroll,
    getHeaderAppearTimeline,
    getOverlayDisappearTween,
  } = useContext(AppPageTransitionContext);

  const facility = useMemo(() => {
    return facilities.find((facility) => facility.id === params.facilityId);
  }, [facilities, params]);

  useEffect(() => {
    if (!facility) {
      return;
    }

    const heroElem = heroRef.current;
    const facilityNameElem = heroElem.querySelector(".facility__name");
    const facilityLogoElem = heroElem.querySelector(".facility__logo");
    const facilityPlaceElem = heroElem.querySelector(".facility__place");
    const facilityYearElem = heroElem.querySelector(".facility__year");

    /**
     * @type HTMLElement[]
     */
    const facilityAboutGroupElms = heroElem.querySelectorAll(
      ".facility-about__group"
    );

    const facilityPreviewImage = heroElem.querySelector(
      ".facility-preview__image"
    );

    const facilityHeaderLine = heroElem.querySelector(".facility__header-line");

    const timeline = gsap.timeline({
      defaults: { ease: "ease-out", duration: 0.6 },
    });

    timeline.add(getOverlayDisappearTween());
    timeline.add(() => {
      window.scrollTo(0, 0);
      disableScroll();
    });
    timeline.add(getHeaderAppearTimeline());

    timeline.from(facilityHeaderLine, {
      scaleY: 0,
      transformOrigin: "top",
      duration: 1,
    });

    timeline.addLabel("start-appear");

    timeline.from(
      [facilityNameElem, facilityLogoElem, facilityPlaceElem, facilityYearElem],
      {
        yPercent: 100,
        stagger: 0.3,
      },
      "start-appear"
    );

    timeline.from(
      facilityPreviewImage,
      {
        y: 50,
        opacity: 0,
      },
      "start-appear"
    );

    facilityAboutGroupElms.forEach((facilityAboutGroupEl) => {
      const facilityAboutTitle = facilityAboutGroupEl.querySelector(
        ".facility-about__title"
      );
      const facilityAboutContentItems = facilityAboutGroupEl.querySelector(
        ".facility-about__main .raw-html"
      );
      const facilityAboutImage = facilityAboutGroupEl.querySelector(
        ".facility-about__image-container .facility-about__image"
      );

      const facilityAboutTimeline = gsap.timeline();

      facilityAboutTimeline.from(facilityAboutGroupEl, {
        scaleX: 0,
        transformOrigin: "left",
      });

      facilityAboutTimeline.from(facilityAboutTitle, {
        yPercent: 100,
      });

      facilityAboutTimeline.from(
        facilityAboutContentItems,
        {
          yPercent: 100,
        },
        "<"
      );

      facilityAboutTimeline.from(facilityAboutImage, {
        yPercent: 100,
      });

      timeline.add(facilityAboutTimeline, "<");
    });

    const galleryImageElms = heroElem.querySelectorAll(
      ".facility-about-gallery__link"
    );

    timeline.from(
      galleryImageElms,
      {
        yPercent: 100,
        stagger: 0.3,
      },
      ">"
    );

    timeline.add(enableScroll);

    return () => {
      timeline.kill();
    };
  }, [enableScroll, getOverlayDisappearTween, getHeaderAppearTimeline]);

  return facility ? (
    <>
      <FacilityHero ref={heroRef} facility={facility} />
      <Footer />
    </>
  ) : (
    <Redirect to={notFoundRoute.path} />
  );
};

export default Facility;
