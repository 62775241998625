import { IMAGES_PATH } from "config/path";

const features = {
  title: "Комплектующие ведущих мировых брендов",
  label: "отличительные особенности наших шкафов",
  about: ["Сертифицированные комплектующие", "Работаем только с\u00a0проверенными поставщиками"],
  content: [
    {
      image: {
        src: `${IMAGES_PATH}/pages/product/switching-equipment/1.png`,
      },
      features: [
        "широкие возможности для настройки под различные комплектации станций",
        "контроль времени наработок насосов и\u00a0их\u00a0периодическое чередование с\u00a0целью равномерного износа",
        "обеспечивает отслеживание аварийных ситуаций и\u00a0защиту оборудования",
      ],
      about:
        "Сертифицирован на\u00a0соответсвие требованиям Технического Регламента Таможенного Союза на\u00a0электромагнитную совместимость и\u00a0безопасность машин и\u00a0оборудования.",
      name: "Специализированному программируемому контроллеру 1",
    },
    {
      image: {
        src: `${IMAGES_PATH}/pages/product/switching-equipment/2.png`,
      },
      features: [
        "сбор информации и\u00a0управление оконечными устройствами посредством встроенных реле;",
        "наличие входов для подключения концевых выключателей по\u00a0схеме NAMUR (для контроля\u00a0КЗ и\u00a0обрыва линии )",
        "информация со\u00a0входов и\u00a0команды управления реле передаются по\u00a0промышленному интерфейсу MODBUS RTU с\u00a0физическим уровнем RS-485",
      ],
      about:
        "Сертифицирован на\u00a0соответсвие требованиям Технического Регламента Таможенного Союза на\u00a0электромагнитную совместимость и\u00a0безопасность машин и\u00a0оборудования.",
      name: "Специализированному программируемому контроллеру 2",
    },
    {
      image: {
        src: `${IMAGES_PATH}/pages/product/switching-equipment/3.png`,
      },
      features: [
        "ввод аналоговых значений с\u00a0промышленных интерфейсов 0-20mA, 0-10V, дискретного входа",
        "управление оконечными устройствами через встроенные реле",
        "Информация с\u00a0входов, информация для выходов и\u00a0команды управления реле передаются по\u00a0промышленному интерфейсу MODBUS RTU с\u00a0физическим уровнем RS-485.",
      ],
      about:
        "Сертифицирован на\u00a0соответсвие требованиям Технического Регламента Таможенного Союза на\u00a0электромагнитную совместимость и\u00a0безопасность машин и\u00a0оборудования.",
      name: "Специализированному программируемому контроллеру 3",
    },
  ],
};

export default features;
