import home from "./home";
import products from "./products";
import about from "./about";
import facilities from "./facilities";
import documentation from "./documentation";
import contacts from "./contacts";
import product from "./product";
import facility from "./facility";
import notFound from "./notFound";
import privacyPolicy from "./privacyPolicy";

const routes = [
  home,
  products,
  product,
  about,
  facilities,
  facility,
  documentation,
  contacts,
  // privacyPolicy,
  notFound,
];

export default routes;
