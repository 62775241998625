import React, { useCallback, useEffect, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ProductsNavCategory from "./ProductsNavCategory";
import { connect } from "react-redux";
import { getProducts } from "store/selectors/app";
import { useTranslation } from "react-i18next";

/**
 * Компонент создает навигацию со всей продукцией
 *
 * @param {Object} props
 * @param {Object} props.className - Класс
 */
function ProductsNav({ className }) {
  const { t } = useTranslation("products");
  const categories = t("categories", { returnObjects: true });

  const headerRef = useRef(null);

  useEffect(() => {
    /**
     * @type HTMLElement
     */
    const headerElem = (headerRef.current = document.querySelector(".header"));

    if (!headerElem) {
      return;
    }

    const headerTransitionendHandler = (e) => {
      if (
        e.target.classList.contains("header-sub-nav") &&
        e.propertyName === "opacity"
      ) {
        headerElem.classList.remove("header--disable-sub-nav-on-hover");
      }
    };

    headerElem.addEventListener("transitionend", headerTransitionendHandler);

    return () => {
      headerElem.removeEventListener(
        "transitionend",
        headerTransitionendHandler
      );
    };
  }, []);

  const hideHeaderSubNav = useCallback(() => {
    const headerElem = headerRef.current;

    headerElem.classList.add("header--disable-sub-nav-on-hover");
  }, []);

  return (
    <nav className={classNames("products-nav", className)}>
      <div className="products-nav__container">
        <ul className="products-nav__groups list row">
          {categories.map((category) => (
            <ProductsNavCategory
              hideHeaderSubNav={hideHeaderSubNav}
              key={category.id}
              category={category}
            />
          ))}
        </ul>
      </div>
    </nav>
  );
}

ProductsNav.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array,
};

export default ProductsNav;
