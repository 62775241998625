import statusIvC from "./status-iv-c";
import statusU from "./status-u";

const subcategory = {
  id: "controllers",
  name: "Controllers",
  products: [statusIvC, statusU],
};

export default subcategory;
