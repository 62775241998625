export default {
  id: "zap-sib-neftekhim",
  name: "ЗапСибНефтехим",
  location: "г. Тобольск",
  year: 2020,

  page: {
    facility: {
      about: [
        {
          title: "В рамках работы по проекту мы выполнили",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Проектирование, разработка и испытание насосной установки;</li>
                  <li>Поставка оборудования транспортной компанией по адресу заказчика;</li>
                  <li>Монтаж и пуско-наладка насосной установки.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "facility-about.webp",
          },
        },
        {
          title: "О заказчике",
          content: [
            {
              type: "html",
              data: `
                <p>ООО «ЗапСибНефтехим» — предприятие по переработке углеводородного сырья и выпуску полимеров, входящее в состав СИБУРа.</p>
                <p>Производственные мощности «ЗапСибНефтехим» включают в себя центральную газофракционирующую установку по переработке широкой фракции легких углеводородов мощностью 8 млн тонн в год, производство мономеров для выработки сжиженных углеводородных газов, бутадиена, изобутилена, а также метил-трет-бутилового эфира; производство полимеров для выработки полипропилена мощностью 500 тыс тонн в год; производство электротеплопарогенерации мощностью 665 МВт по электрической энергии и 2 585 МВт по тепловой энергии.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}, {}],
    },
  },
};
