export default {
  id: "golden-horn",
  name: "Zolotoj Rog",
  location: "Oktyabrsky",
  year: 2017,

  page: {
    facility: {
      about: [
        {
          title: "As part of the work on the project, we have made",
          content: [
            {
              type: "html",
              data: `
              <ul>
                <li>Visit to the client’s site for consultation and assessment of operating conditions;</li>
                <li>Development, testing, installation of a pumping unit.</li>
              </ul>
              `,
            },
          ],
          image: {
            file: "about-works-done.webp",
          },
        },
        {
          title: "About the client",
          content: [
            {
              type: "html",
              data: `
                <p>Today LTD «Meat Processing Plant Zolotoy Rog» has a strong production base, including production, administrative and transport buildings.</p>
                <p>The production is fitted with modern German equipment — world famous companies: KG Wetter, Vemag, Polyclip, Hadtman, Varlovac, Bastra. The existing equipment is fitted with a quality safety control system, which eliminates the «human factor».</p>
                <p>Sausages and delicacies are made only from chilled meat, that allows to preserve the taste of the products.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}],
    },
  },
};
