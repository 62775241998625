export default {
  id: "bc-4000",
  name: "BC-4000",
  image: {
    main: {},
  },
  about: {
    description: [
      [
        {
          type: "text",
          data: "Panel block containers are designed for use in the temperature range from −60C ° to + 40C °, used for placing and storing equipment. The standard package includes:",
        },
      ],
      [
        {
          type: "list",
          data: [
            "Basic and emergency lighting;",
            "Fire extinguishing system;",
            "Exhaust system;",
            "Fire alarm system;",
            "Manual fire extinguishing kit (carbon dioxide extinguisher).",
          ],
        },
      ],
    ],
    specifications: [
      {
        name: "Сharacteristics",
        data: [
          {
            type: "list",
            data: [
              "Container size 4000×2300×2500;",
              "Acceptable equipment dimensions: 2800×1500×2100;",
              "Lighting;",
              "Electrical heating;",
              "In-house cabinet;",
              "Electric ventilation blinds;",
              "Power metal frame made of cold-resistant and wear-resistant steel;",
              "Walls and ceiling inside are made of 100 mm thick sandwich panels;",
              "Holes for I/O cable.",
            ],
          },
        ],
      },
    ],
  },
};
