import { About } from "components/pages";

const route = {
  path: "/about",
  exact: true,
  component: About,
  className: "about",
  nav: {
    label: "about",
  },
};

export default route;
