export default {
  id: "grand-master",
  name: "Grand Master",
  location: "г. Москва",
  year: 2019,

  page: {
    facility: {
      gallery: [{}, {}, {}],
      about: [
        {
          title: "В&nbsp;рамках работы по&nbsp;проекту мы&nbsp;выполнили",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Произведены все монтажные работы по&nbsp;оборудованию;</li>
                  <li>Выполнены работы по&nbsp;подключению и&nbsp;настройке насосных станций повышения давления, а&nbsp;также ввод в&nbsp;эксплуатацию оборудования с&nbsp;соблюдением всех требований.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "about-project.webp",
          },
        },
        {
          title: "О&nbsp;заказчике:",
          content: [
            {
              type: "html",
              data: `
                <p>Техника MIE и&nbsp;Grand Master представлена во&nbsp;всех крупных городах России, в&nbsp;том числе в&nbsp;фирменных магазинах компании, в&nbsp;сетевых магазинах бытовой техники и&nbsp;электроники и&nbsp;интернет-гипермаркетах.</p>
                <p>Grand Master представляет обширную сеть авторизованных сервисных центров, осуществляющих гарантийное и&nbsp;послегарантийное обслуживание техники, поставляет запасные части и&nbsp;аксессуары.</p>
              `,
            },
          ],
        },
      ],
    },
  },
};
