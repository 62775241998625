import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
} from "react";
import classNames from "classnames";
import HeaderLogo from "./HeaderLogo";
import HeaderNav from "./HeaderNav";
import HeaderContact from "./HeaderContact";
import HeaderMenu from "./HeaderMenu";
import { ScrollProgress } from "components/common";
import { AppThemeContext } from "App";
import { BodyScrollContext } from "App";
import HeaderLanguageSwitcher from "./HeaderLanguageSwitcher";

const Header = forwardRef(({ menuRef }, ref) => {
  const headerRef = useRef(null);
  const { headerTheme, setHeaderTheme } = useContext(AppThemeContext);
  const { scrollbarGap } = useContext(BodyScrollContext);

  useImperativeHandle(ref, () => headerRef.current);

  return (
    <header
      ref={headerRef}
      className={classNames("header", {
        [`theme_${headerTheme.theme}`]: headerTheme.theme,
        [`theme_changing`]: headerTheme.isChanging,
      })}
      style={{ paddingRight: scrollbarGap + "px" }}
    >
      <div className="header__container container">
        <div className="header__inner row">
          <div className="header__left col-auto col-sm-3 col-md-2">
            <HeaderLogo className="header__logo " />
          </div>

          <div className="header__middle col-7 col-xl">
            <HeaderNav
              setHeaderTheme={setHeaderTheme}
              headerTheme={headerTheme.theme}
              className="header__nav"
            />
          </div>

          <div className="header__right col-auto  ">
            <HeaderLanguageSwitcher />
            <HeaderContact className="header__contact" />
            <HeaderMenu menuRef={menuRef} className="header__menu-toggle" />
          </div>
        </div>
      </div>
      <ScrollProgress />
    </header>
  );
});

export default Header;
