import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import gsap from "gsap";

const Divider = ({ className }) => {
  const elRef = useRef(null);
  const dividerElRef = useRef(null);

  useEffect(() => {
    const el = elRef.current;
    const dividerEl = dividerElRef.current;

    gsap.from(dividerEl, {
      scrollTrigger: {
        trigger: el,
      },
      xPercent: 0,
      transformOrigin: "left",
      delay: 0.2,
    });
  }, []);

  return (
    <div ref={elRef}>
      <hr ref={dividerElRef} className={classNames("divider", className)} />
    </div>
  );
};

export default Divider;
