export default {
  id: "oao-snpz",
  name: "ОАО СНПЗ",
  location: "г. Сызрань",
  year: 2019,

  page: {
    facility: {
      about: [
        {
          title: "В рамках работы по проекту мы выполнили",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Произведен комплекс работ по разработке и изготовлению оборудования;</li>
                  <li>Выполнен шеф-монтаж и пусконаладка автоматической насосной установки на объекте заказчика.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "about-works-done.webp",
          },
        },
        {
          title: "О заказчике",
          content: [
            {
              type: "html",
              data: `
                <p>Проектная мощность НПЗ составляет 8,5 млн. т нефти в год. Завод перерабатывает Западно-Сибирскую нефть, нефть Оренбургских месторождений, а также нефть, добываемую Компанией в Самарской области.</p>
                <p>Вторичные перерабатывающие мощности завода включают установки каталитического риформинга, гидроочистки топлив, легкого гидрокрекинга, каталитического и термического крекинга, изомеризации, битумную и газофракционную установки, блок выделения бензолсодержащей фракции. Завод выпускает широкую номенклатуру нефтепродуктов, включая высококачественное моторное топливо, низкосернистое судовое топливо RMLS 40 вид Э II, битум.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}],
    },
  },
};
