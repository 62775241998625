import { getProductManualSrc } from "utils/path";

export default {
  id: "control-gc",
  name: "Control GC",
  type: "каскадный",
  about: {
    description: [
      [
        {
          type: "text",
          data:
            "Шкаф управления предназначен для поддержания и&nbsp;регулирования уровня контролируемого параметра (давления системы водоснабжения, уровня наполнения резервуаров и&nbsp;т.п.), путем последовательного (каскадного) подключения электродвигателей для поддержания заданного значения параметра.",
        },
      ],
      [
        {
          type: "list",
          data: [
            "Соответствие TP&nbsp;TC&nbsp;004/2011 &laquo;О&nbsp;безопасности низковольтного оборудования&raquo;;",
            "Соответствие ТР&nbsp;ТС&nbsp;020/2011 &laquo;Электромагнитная совместимость технических средств&raquo;;",
            "Интуитивно понятный интерфейс;",
            "Гибкость настройки.",
          ],
        },
      ],
    ],
    specifications: [
      {
        name: "Основные функции",
        data: [
          {
            type: "list",
            data: [
              "Защита электродвигателей насосов;",
              "Автоматическое и&nbsp;ручное управление насосами;",
              "Автоматический пуск основных насосов в&nbsp;соответствии с&nbsp;сигналами управления;",
              "Автоматический пуск резервного насоса в&nbsp;случае отказа или невыхода основного насоса на&nbsp;режим в&nbsp;течение заданного времени;",
              "Ручное отключение автоматического пуска насосов с&nbsp;сохранением возможности ручного пуска;",
              "Индикация состояния системы с&nbsp;помощью световой индикации;",
              "Визуальное отображение состояния системы и&nbsp;настройка необходимых параметров с помощью панели оператора;",
              "Звуковая сигнализация состояния &laquo;НЕИСПРАВНОСТЬ&raquo;;",
              "Диспетчеризация состояния системы с&nbsp;помощью сухих контактов (стандартно) или интерфейсной линия связи (опционально).",
            ],
          },
        ],
      },
      {
        name: "Дополнительные опции",
        data: [
          {
            type: "list",
            data: [
              "Предусматривает световую и&nbsp;звуковую сигнализацию, выбор режимов работы и&nbsp;возможность настройки необходимых параметров, диспетчеризацию с&nbsp;помощью сухих контактов (стандартно) или интерфейсной линии связи (опционально).",
              "Настраивается и&nbsp;тестируется на&nbsp;предприятии изготовителе. Оборудование поставляется готовым к&nbsp;подключению и&nbsp;эксплуатации.",
            ],
          },
        ],
      },
      {
        name: "Отличительные особенности",
        data: [
          {
            type: "list",
            data: [
              "Соответствие TP&nbsp;TC&nbsp;004/2011 &laquo;О&nbsp;безопасности низковольтного оборудования&raquo;",
              "Соответствие ТР&nbsp;ТС&nbsp;020/2011 &laquo;Электромагнитная совместимость технических средств&raquo;",
              "Интуитивно понятный интерфейс",
              "Гибкость настройки",
            ],
          },
        ],
      },
      {
        name: "Документация",
        data: [
          {
            type: "cards",
            data: [
              {
                type: "documentation",
                data: {
                  caption: "руководство по эксплуатации",
                  title: "Control GC",
                  file: getProductManualSrc({
                    categoryId: "control-cabinets",
                    subcategoryId: "water-supply",
                    productId: "control-gc",
                  }),
                },
              },
            ],
          },
        ],
      },
    ],
  },
};
