export default {
  id: "nevinnomyssky-azot",
  name: "Невинномысский Азот",
  location: "г. Невинномысск",
  year: 2018,

  page: {
    facility: {
      about: [
        {
          title: "В рамках работы по проекту мы выполнили",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Монтаж автоматической насосной станции повышения давления;</li>
                  <li>Проверка и запуск в эксплуатацию оборудования автоматического пожаротушения.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "facility-about.webp",
          },
        },
        {
          title: "О заказчике",
          content: [
            {
              type: "html",
              data: `
                <p>АО «Невинномысский Азот» — крупнейшее на Юге России предприятие химической промышленности, выпускающее широкую номенклатуру минеральных удобрений и продуктов органического синтеза, реализуемых как на внутреннем рынке, так и на экспорт.</p>
                <p>АО «Невинномысский Азот» располагает единственными в России установками по производству меламина, метилацетата, уксусной кислоты высокой чистоты.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}],
    },
  },
};
