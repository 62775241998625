import { AppColor } from "config/index";

import bc9000 from "./bc-9000";

const subcategory = {
  id: "medium",
  name: "Средние",
  products: [bc9000],
};

export default subcategory;
