import React from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const HeaderLanguageSwitcher = (props) => {
  const { i18n } = useTranslation();

  const nextLanguage = useMemo(() => {
    return i18n.language === "ru" ? "en" : "ru";
  }, [i18n.language]);

  const toggleLanguage = useCallback(() => {
    i18n.changeLanguage(nextLanguage);
  }, [i18n, nextLanguage]);

  return (
    <button
      onClick={toggleLanguage}
      className="header__language-switcher nav__link button link"
    >
      {nextLanguage}
    </button>
  );
};

export default HeaderLanguageSwitcher;
