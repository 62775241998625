export default {
  id: "golden-horn",
  name: "Золотой Рог",
  location: "Пос. Октябрьский",
  year: 2017,

  page: {
    facility: {
      about: [
        {
          title: "В рамках работы по проекту мы выполнили",
          content: [
            {
              type: "html",
              data: `
              <ul>
                <li>Выезд на объект заказчика для консультации и оценки эксплуатационных условий;</li>
                <li>Разработка и испытание насосной установки, монтаж.</li>
              </ul>
              `,
            },
          ],
          image: {
            file: "about-works-done.webp",
          },
        },
        {
          title: "О заказчике",
          content: [
            {
              type: "html",
              data: `
                <p>На сегодняшний день, ООО «Мясокомбинат «Золотой Рог» имеет сильную производственную базу, включающую в себя производственный, административный и транспортный корпуса.</p>
                <p>Производство оснащено современным германским оборудованием — известных в мире компании: KG Wetter, Vemag, Polyclip, Hadtman, Varlovac, Bastra. Имеющееся оборудование оснащено системой контроля за безопасностью качества, что позволяет исключить «человеческий фактор».</p>
                <p>Колбасные изделия и деликатесы производятся только из охлажденного мяса, что позволяет сохранить вкусовые качества изделий.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}],
    },
  },
};
