export default {
  id: "oao-snpz",
  name: "JSC SNPZ",
  location: "Syzran",
  year: 2019,

  page: {
    facility: {
      about: [
        {
          title: "As part of the work on the project, we have made",
          content: [
            {
              type: "html",
              data: `
                <ul>
                  <li>Сomplex of works on the development and manufacture of equipment;</li>
                  <li>Installation and commissioning of an automatic pumping unit at the client’s site.</li>
                </ul>
              `,
            },
          ],
          image: {
            file: "about-works-done.webp",
          },
        },
        {
          title: "About the client",
          content: [
            {
              type: "html",
              data: `
                <p>The factory processes West Siberian oil, oil from the Orenburg fields, as well as oil produced by the company in Samara region.</p>
                <p>The plant’s secondary processing facilities include catalytic reforming, fuel hydrotreating, light hydrocracking, catalytic and thermal cracking, isomerization, bitumen and gas fractionation units, and a benzene-containing fraction separation unit. The factory produces a wide range of petroleum products, including high-quality motor fuel, low-sulfur marine fuel RMLS 40 type E II, bitumen.</p>
              `,
            },
          ],
        },
      ],
      gallery: [{}, {}],
    },
  },
};
